import { alpha } from '@mui/material/styles';
import { tableCellClasses } from '@mui/material/TableCell';
import { tableRowClasses } from '@mui/material/TableRow';
import { margin } from '@mui/system';

// // ----------------------------------------------------------------------

// export function table(theme) {
//   return {
//     MuiTableContainer: {
//       styleOverrides: {
//         root: {
//           position: 'relative',
//         },
//       },
//     },
//     MuiTableRow: {
//       styleOverrides: {
//         root: {
//           [`&.${tableRowClasses.selected}`]: {
//             backgroundColor: alpha(theme.palette.primary.dark, 0.04),
//             '&:hover': {
//               backgroundColor: alpha(theme.palette.primary.dark, 0.08),
//             },
//           },
//           '&:last-of-type': {
//             [`& .${tableCellClasses.root}`]: {
//               borderColor: 'transparent',
//             },
//           },
//         },
//       },
//     },
//     MuiTable: {
//        styleOverrides: {
//        root: {
//                  fontSize: 10.5, // Ensure the overall font size is small
//               },
//              },
//            },
//     MuiTableCell: {
//       styleOverrides: {
//         root: {
//           fontSize: 10.5, // Minimized font size for table cells
//           padding: theme.spacing(0.5), // Minimal padding for compactness
//           borderBottomStyle: 'dashed',
//         },
//         head: {
//           fontSize: 11,
//           color: theme.palette.text.secondary,
//           fontWeight: theme.typography.fontWeightSemiBold,
//           backgroundColor: theme.palette.background.neutral,
//         },
//         stickyHeader: {
//           backgroundColor: theme.palette.background.paper,
//           backgroundImage: `linear-gradient(to bottom, ${theme.palette.background.neutral} 0%, ${theme.palette.background.neutral} 100%)`,
//         },
//         paddingCheckbox: {
//           paddingLeft: theme.spacing(1),
//         },
//       },
//     },
    
//     MuiTablePagination: {
//       styleOverrides: {
//         root: {
//           width: '100%',
//         },
//         toolbar: {
//           height: 64,
//         },
//         actions: {
//           marginRight: 8,
//         },
//         select: {
//           paddingLeft: 8,
//           '&:focus': {
//             borderRadius: theme.shape.borderRadius,
//           },
//         },
//         selectIcon: {
//           right: 4,
//           width: 16,
//           height: 16,
//           top: 'calc(50% - 8px)',
//         },
//       },
//     },
//   };
// }


// import { alpha } from '@mui/material/styles';
// import { tableCellClasses } from '@mui/material/TableCell';
// import { tableRowClasses } from '@mui/material/TableRow';

// ----------------------------------------------------------------------

// export function table(theme) {
//   return {
//     MuiTableContainer: {
//       styleOverrides: {
//         root: {
//           position: 'relative',
//           overflowX: 'auto', // Enable horizontal scrolling if the table has too many columns
//         },
//       },
//     },
//     MuiTableCell: {
//       styleOverrides: {
//         root: {
//           fontSize: 10.5, // Minimized font size for table cells
//           padding: theme.spacing(0.5), // Minimal padding for compactness
//           borderBottomStyle: 'dashed',
//         },
//         head: {
//           fontSize: 11, // Minimized font size for table headings
//           color: theme.palette.text.secondary,
//           fontWeight: theme.typography.fontWeightSemiBold,
//           backgroundColor: theme.palette.background.neutral,
//           padding: theme.spacing(0.5), // Minimal padding in the header
//         },
//       },
//     },
//     MuiTableRow: {
//       styleOverrides: {
//         root: {
//           [`&.${tableRowClasses.selected}`]: {
//             backgroundColor: alpha(theme.palette.primary.dark, 0.04),
//             '&:hover': {
//               backgroundColor: alpha(theme.palette.primary.dark, 0.08),
//             },
//           },
//         },
//       },
//     },
//     MuiTablePagination: {
//       styleOverrides: {
//         root: {
//           fontSize: 10, // Minimized font size for pagination controls (Rows per page)
//           margin: '1px auto' , // Added margin
//         },
//         toolbar: {
//           height: 48, // Minimized height of the toolbar
//         },
//       },
//     },    
//     MuiTable: {
//       styleOverrides: {
//         root: {
//           fontSize: 10, // Ensure the overall font size is small
//         },
//       },
//     },
//     MuiTableSortLabel: {
//       styleOverrides: {
//         root: {
//           fontSize: 10, // Minimized font size for sort labels in the header
          
//         },
//       },
//     },
//   };
// }

// -------------------------------------updated table.js--------------------------------------------
export function table(theme) {
  return {
    MuiTableContainer: {
      styleOverrides: {
        root: {
          position: 'relative',
        },
      },
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          [`&.${tableRowClasses.selected}`]: {
            backgroundColor: alpha(theme.palette.primary.dark, 0.04),
            '&:hover': {
              backgroundColor: alpha(theme.palette.primary.dark, 0.08),
            },
          },
          '&:last-of-type': {
            [`& .${tableCellClasses.root}`]: {
              borderColor: 'transparent',
            },
          },
        },
      },
    },
    MuiTable: {
      styleOverrides: {
        root: {
          fontSize: 10, // Ensure small font size for overall table
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          // fontSize: 10.5, // Smaller font size for table cells
          fontSize: 11.8, // Smaller font size for table cells
          padding: theme.spacing(0.5), // Compact padding for all table cells
          borderBottomStyle: 'dashed',
        },
        head: {
          fontSize: '12px !important', // Force 11px for table heading
          color: theme.palette.text.secondary,
          fontWeight: theme.typography.fontWeightSemiBold,
          backgroundColor: theme.palette.background.neutral,
          padding: theme.spacing(0.5, 1), // Reduced vertical and horizontal padding
          whiteSpace: 'nowrap', // Prevent text from wrapping and adding unnecessary height
        },
        stickyHeader: {
          backgroundColor: theme.palette.background.paper,
          backgroundImage: `linear-gradient(to bottom, ${theme.palette.background.neutral} 0%, ${theme.palette.background.neutral} 100%)`,
        },
        paddingCheckbox: {
          paddingLeft: theme.spacing(1), // Minimal padding for checkbox cells
        },
      },
    },
    MuiTablePagination: {
      styleOverrides: {
        root: {
          width: '100%',
        },
        toolbar: {
          height: 64,
        },
        actions: {
          marginRight: 8,
        },
        select: {
          paddingLeft: 8,
          '&:focus': {
            borderRadius: theme.shape.borderRadius,
          },
        },
        selectIcon: {
          right: 4,
          width: 16,
          height: 16,
          top: 'calc(50% - 8px)',
        },
      },
    },
  };
}
