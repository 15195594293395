import PropTypes from 'prop-types';
import { useFormContext, Controller } from 'react-hook-form';
// @mui
import FormHelperText from '@mui/material/FormHelperText';
//
import { UploadAvatar, Upload, UploadBox } from '../upload';

export function RHFUploadAvatar({ name, acceptFile, maxSize = 3 * 1024 * 1024, ...other }) {
  const { control } = useFormContext();

  const getFileErrorMessage = (file) => {
    if (file?.size > maxSize) {
      return `File exceeds the maximum size of ${(maxSize / (1024 * 1024)).toFixed(2)} MB.`;
    }
    return null;
  };

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => {
        const fileErrorMessage = field.value ? getFileErrorMessage(field.value) : null;

        return (
          <div>
            <UploadAvatar
              error={!!error || !!fileErrorMessage}
              file={field.value}
              maxSize={maxSize}
              helperText={
                <>
                  {!!error && (
                    <FormHelperText error sx={{ px: 2, textAlign: 'center' }}>
                      {error.message}
                    </FormHelperText>
                  )}
                  {!!fileErrorMessage && (
                    <FormHelperText error sx={{ px: 2, textAlign: 'center' }}>
                      {fileErrorMessage}
                    </FormHelperText>
                  )}
                </>
              }
              {...field}
              {...other}
            />
          </div>
        );
      }}
    />
  );
}

RHFUploadAvatar.propTypes = {
  name: PropTypes.string.isRequired,
  acceptFile: PropTypes.object.isRequired,
  maxSize: PropTypes.number,
};

export function RHFUploadBox({
  name,
  helperText,
  acceptFile,
  maxSize = 3 * 1024 * 1024,
  ...other
}) {
  const { control } = useFormContext();
  console.log(acceptFile, 'acceptFile');

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <UploadBox
          acceptFile={acceptFile}
          file={field.value}
          error={!!error}
          helperText={
            (!!error || helperText) && (
              <FormHelperText error={!!error} sx={{ px: 2 }}>
                {error ? error?.message : helperText}
              </FormHelperText>
            )
          }
          maxSize={maxSize}
          {...other}
        />
      )}
    />
  );
}

RHFUploadBox.propTypes = {
  helperText: PropTypes.string,
  name: PropTypes.string.isRequired,
  acceptFile: PropTypes.object.isRequired,
  maxSize: PropTypes.number,
};

export function RHFUpload({ name, multiple, acceptFile, helperText, ...other }) {
  const { control } = useFormContext();
  console.log(acceptFile, 'acceptFile');

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) =>
        multiple ? (
          <Upload
            multiple
            accept={acceptFile}
            files={field.value}
            error={!!error}
            helperText={
              (!!error || helperText) && (
                <FormHelperText error={!!error} sx={{ px: 2 }}>
                  {error ? error?.message : helperText}
                </FormHelperText>
              )
            }
            {...other}
          />
        ) : (
          <Upload
            file={field.value}
            error={!!error}
            helperText={
              (!!error || helperText) && (
                <FormHelperText error={!!error} sx={{ px: 2 }}>
                  {error ? error?.message : helperText}
                </FormHelperText>
              )
            }
            {...other}
          />
        )
      }
    />
  );
}

RHFUpload.propTypes = {
  helperText: PropTypes.string,
  multiple: PropTypes.bool,
  name: PropTypes.string,
  acceptFile: PropTypes.object.isRequired, // Accepted file types (e.g., "image/*,application/pdf")
};
