// import { alpha } from '@mui/material/styles';
// import { inputBaseClasses } from '@mui/material/InputBase';
// import { inputLabelClasses } from '@mui/material/InputLabel';
// import { filledInputClasses } from '@mui/material/FilledInput';
// import { outlinedInputClasses } from '@mui/material/OutlinedInput';

// // ----------------------------------------------------------------------

// export function textField(theme) {
//   const color = {
//     focused: theme.palette.text.primary,
//     active: theme.palette.text.secondary,
//     placeholder: theme.palette.text.disabled,
//   };

//   const font = {
//     label: theme.typography.body1,
//     value: theme.typography.body2,
//   };

//   return {
//     // HELPER
//     MuiFormHelperText: {
//       styleOverrides: {
//         root: {
//           marginTop: theme.spacing(1),
//         },
//       },
//     },

//     // LABEL
//     MuiFormLabel: {
//       styleOverrides: {
//         root: {
//           ...font.value,
//           color: color.placeholder,
//           [`&.${inputLabelClasses.shrink}`]: {
//             ...font.label,
//             fontWeight: 600,
//             color: color.active,
//             [`&.${inputLabelClasses.focused}`]: {
//               color: color.focused,
//             },
//             [`&.${inputLabelClasses.error}`]: {
//               color: theme.palette.error.main,
//             },
//             [`&.${inputLabelClasses.disabled}`]: {
//               color: theme.palette.text.disabled,
//             },
//             [`&.${inputLabelClasses.filled}`]: {
//               transform: 'translate(12px, 6px) scale(0.75)',
//             },
//           },
//         },
//       },
//     },

//     // BASE
//     MuiInputBase: {
//       styleOverrides: {
//         root: {
//           [`&.${inputBaseClasses.disabled}`]: {
//             '& svg': {
//               color: theme.palette.text.disabled,
//             },
//           },
//         },
//         input: {
//           ...font.value,
//           '&::placeholder': {
//             opacity: 1,
//             color: color.placeholder,
//           },
//         },
//       },
//     },

//     // STANDARD
//     MuiInput: {
//       styleOverrides: {
//         underline: {
//           '&:before': {
//             borderBottomColor: alpha(theme.palette.grey[500], 0.32),
//           },
//           '&:after': {
//             borderBottomColor: color.focused,
//           },
//         },
//       },
//     },

//     // OUTLINED
//     MuiOutlinedInput: {
//       styleOverrides: {
//         root: {
//           [`&.${outlinedInputClasses.focused}`]: {
//             [`& .${outlinedInputClasses.notchedOutline}`]: {
//               borderColor: color.focused,
//             },
//           },
//           [`&.${outlinedInputClasses.error}`]: {
//             [`& .${outlinedInputClasses.notchedOutline}`]: {
//               borderColor: theme.palette.error.main,
//             },
//           },
//           [`&.${outlinedInputClasses.disabled}`]: {
//             [`& .${outlinedInputClasses.notchedOutline}`]: {
//               borderColor: theme.palette.action.disabledBackground,
//             },
//           },
//         },
//         notchedOutline: {
//           borderColor: alpha(theme.palette.grey[500], 0.2),
//           transition: theme.transitions.create(['border-color'], {
//             duration: theme.transitions.duration.shortest,
//           }),
//         },
//       },
//     },

//     // FILLED
//     MuiFilledInput: {
//       styleOverrides: {
//         root: {
//           borderRadius: theme.shape.borderRadius,
//           backgroundColor: alpha(theme.palette.grey[500], 0.08),
//           '&:hover': {
//             backgroundColor: alpha(theme.palette.grey[500], 0.16),
//           },
//           [`&.${filledInputClasses.focused}`]: {
//             backgroundColor: alpha(theme.palette.grey[500], 0.16),
//           },
//           [`&.${filledInputClasses.error}`]: {
//             backgroundColor: alpha(theme.palette.error.main, 0.08),
//             [`&.${filledInputClasses.focused}`]: {
//               backgroundColor: alpha(theme.palette.error.main, 0.16),
//             },
//           },
//           [`&.${filledInputClasses.disabled}`]: {
//             backgroundColor: theme.palette.action.disabledBackground,
//           },
//         },
//       },
//     },
//   };
// }


import { alpha } from '@mui/material/styles';
import { inputBaseClasses } from '@mui/material/InputBase';
import { inputLabelClasses } from '@mui/material/InputLabel';
import { filledInputClasses } from '@mui/material/FilledInput';
import { outlinedInputClasses } from '@mui/material/OutlinedInput';

// ----------------------------------------------------------------------

export function textField(theme) {
  const color = {
    focused: theme.palette.text.primary,
    active: theme.palette.text.secondary,
    placeholder: theme.palette.text.disabled,
  };

  const font = {
    label: theme.typography.body1,
    value: theme.typography.body2,
  };

  return {
    // HELPER
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          marginTop: theme.spacing(1),
        },
      },
    },

    // LABEL
    MuiFormLabel: {
      styleOverrides: {
        root: {
          ...font.value,
          color: color.placeholder,
          [`&.${inputLabelClasses.shrink}`]: {
            ...font.label,
            fontWeight: 600,
            color: color.active,
            [`&.${inputLabelClasses.focused}`]: {
              color: color.focused,
            },
            [`&.${inputLabelClasses.error}`]: {
              color: theme.palette.error.main,
            },
            [`&.${inputLabelClasses.disabled}`]: {
              color: theme.palette.text.disabled,
            },
            [`&.${inputLabelClasses.filled}`]: {
              transform: 'translate(12px, 6px) scale(0.75)',
            },
          },
        },
      },
    },

    // BASE
    MuiInputBase: {
      styleOverrides: {
        root: {
          [`&.${inputBaseClasses.disabled}`]: {
            '& svg': {
              color: theme.palette.text.disabled,
            },
          },
        },
        input: {
          ...font.value,
          padding: '6px 8px', // Adjust padding for Base Input
          '&::placeholder': {
            opacity: 1,
            color: color.placeholder,
          },
        },
      },
    },

    // STANDARD
    MuiInput: {
      styleOverrides: {
        underline: {
          '&:before': {
            borderBottomColor: alpha(theme.palette.grey[500], 0.32),
          },
          '&:after': {
            borderBottomColor: color.focused,
          },
        },
      },
    },

    MuiInputLabel: {
      styleOverrides: {
        root: {
          transform: 'translate(9px, 9px) scale(1)', // Adjust label position
          [`&.${inputLabelClasses.shrink}`]: {
            transform: 'translate(14px, -6px) scale(0.75)', // Adjust label position when focused or shrink
          },
        },
      },
    },
    
    // OUTLINED
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          [`&.${outlinedInputClasses.focused}`]: {
            [`& .${outlinedInputClasses.notchedOutline}`]: {
              borderColor: color.focused,
            },
          },
          [`&.${outlinedInputClasses.error}`]: {
            [`& .${outlinedInputClasses.notchedOutline}`]: {
              borderColor: theme.palette.error.main,
            },
          },
          [`&.${outlinedInputClasses.disabled}`]: {
            [`& .${outlinedInputClasses.notchedOutline}`]: {
              borderColor: theme.palette.action.disabledBackground,
            },
          },
        },
        input: {
          padding: '10px 14px', // Ensure consistent padding inside the input field
        },
        notchedOutline: {
          borderColor: alpha(theme.palette.grey[500], 0.2),
          transition: theme.transitions.create(['border-color'], {
            duration: theme.transitions.duration.shortest,
          }),
        },
      },
    },

    // FILLED
    MuiFilledInput: {
      styleOverrides: {
        root: {
          borderRadius: theme.shape.borderRadius,
          backgroundColor: alpha(theme.palette.grey[500], 0.08),
          '&:hover': {
            backgroundColor: alpha(theme.palette.grey[500], 0.16),
          },
          [`&.${filledInputClasses.focused}`]: {
            backgroundColor: alpha(theme.palette.grey[500], 0.16),
          },
          [`&.${filledInputClasses.error}`]: {
            backgroundColor: alpha(theme.palette.error.main, 0.08),
            [`&.${filledInputClasses.focused}`]: {
              backgroundColor: alpha(theme.palette.error.main, 0.16),
            },
          },
          [`&.${filledInputClasses.disabled}`]: {
            backgroundColor: theme.palette.action.disabledBackground,
          },
        },
        input: {
          padding: '6px 8px', // Adjust padding for Filled Input
        },
      },
    },
  };
}
