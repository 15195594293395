import PropTypes from 'prop-types';
import { useEffect, useReducer, useCallback, useMemo, useState } from 'react';
import axios, { endpoints } from 'src/utils/axios'; // Import your Axios instance
import { getCurrentProject, getCurrentServer, getMandEndPoint } from 'src/helper/server-address';
import useNavigateErrorHandler from 'src/hooks/useNavigateErrorHandler';
import { AuthContext } from './auth-context';
import { isValidToken, setSession } from './utils';

const initialState = {
  user: null,
  loading: true,
};

const reducer = (state, action) => {
  if (action.type === 'INITIAL') {
    return {
      loading: false,
      user: action.payload.user,
    };
  }
  if (action.type === 'LOGIN') {
    return {
      ...state,
      user: action.payload.user,
    };
  }
  if (action.type === 'REGISTER') {
    return {
      ...state,
      user: action.payload.user,
    };
  }
  if (action.type === 'LOGOUT') {
    return {
      ...state,
      user: null,
    };
  }
  return state;
};

const STORAGE_KEY = 'token';

export function AuthProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const handleApiError = useNavigateErrorHandler();
  const [userRoles, setUserRoles] = useState([]);

  const initialize = useCallback(async () => {
    try {
      const token = sessionStorage.getItem('token');
      const users = JSON.parse(sessionStorage.getItem('user'));
      console.log(":users", users);
      

      // const token = sessionStorage.getItem(STORAGE_KEY);

      if (token && isValidToken(token)) {
        setSession(token);
        // console.log(token, " in valid token======");

        const response = await axios.get(
          `${getCurrentServer()}/${getCurrentProject()}/${getMandEndPoint()}/users/oneuserSession/${
            users.userId
          }`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        ); // Replace with your JWT auth endpoint
        // const response = await axios.get(endpoints.auth.me);
        console.log(response.data,"all user data");
        const user = response.data;
        // Save user object in session storage
        sessionStorage.setItem('user', JSON.stringify(user));

        const response2 = await axios.post(
          `${getCurrentServer()}/${getCurrentProject()}/${getMandEndPoint()}/userrole/getbyuser`,
          user, // Pass the user object in the request body
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const sites = response2.data;
        sessionStorage.setItem('selectedSubscrSite', JSON.stringify(sites));

        //  sessionStorage.setItem('subscrSiteId', 1);
        console.log(user, 'userrrrrrrr');
        dispatch({
          type: 'INITIAL',
          payload: {
            user,
          },
        });
      } else {
        dispatch({
          type: 'INITIAL',
          payload: {
            user: null,
          },
        });
      }
    } catch (error) {
      console.error(error);
      dispatch({
        type: 'INITIAL',
        payload: {
          user: null,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    initialize();
  }, [initialize]);

  const updateRoles = useCallback((newRoles) => {
    setUserRoles(newRoles);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // LOGIN
  const login = useCallback(async (email, password) => {
    try {
      const data = {
        email,
        password,
      };

      const response = await axios.post(
        `${getCurrentServer()}/${getCurrentProject()}/${getMandEndPoint()}/auth/loginAuth`,
        data
      ); // Replace with your JWT auth login endpoint
      console.log("response.data auth-", response.data);
      
      const { token, user } = response.data;

      setSession(token, user);
      console.log(token, 'tokennnnnn');

      dispatch({
        type: 'LOGIN',
        payload: {
          user,
        },
      });
    } catch (error) {
      console.error(error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // REGISTER
  const register = useCallback(async (email, password, firstName, lastName) => {
    try {
      const data = {
        email,
        password,
        firstName,
        lastName,
      };

      const response = await axios.post('/auth/register', data); // Replace with your JWT auth registration endpoint

      const { token, user } = response.data;

      sessionStorage.setItem(STORAGE_KEY, token);

      // localStorage.setItem(STORAGE_KEY, token);

      dispatch({
        type: 'REGISTER',
        payload: {
          user,
        },
      });
    } catch (error) {
      console.error(error);
      // Handle error appropriately
      if (error.response.status === '500') {
        handleApiError(error);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // LOGOUT
  const logout = useCallback(async () => {
    setSession(null);
    dispatch({
      type: 'LOGOUT',
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // CONFIRM REGISTER
  const confirmRegister = useCallback(async (email, code) => {
    try {
      const data = {
        email,
        code,
      };

      await axios.post('/auth/confirm-register', data); // Replace with your JWT auth confirm registration endpoint

      // Handle success or errors as needed
    } catch (error) {
      console.error(error);
      // Handle error appropriately
      if (error.response.status === '500') {
        handleApiError(error);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // RESEND CODE REGISTER
  const resendCodeRegister = useCallback(async (email) => {
    try {
      const data = {
        email,
      };

      await axios.post('/auth/resend-code-register', data); // Replace with your JWT auth resend code endpoint

      // Handle success or errors as needed
    } catch (error) {
      console.error(error);
      // Handle error appropriately
      if (error.response.status === '500') {
        handleApiError(error);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // FORGOT PASSWORD
  const forgotPassword = useCallback(async (email) => {
    try {
      const data = {
        email,
      };

      // Handle success or errors as needed
    } catch (error) {
      console.error(error);
      // Handle error appropriately
      if (error.response.status === '500') {
        handleApiError(error);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // NEW PASSWORD
  // const newPassword = useCallback(async (email, code, password) => {
  //   try {
  //     const data = {
  //       email,
  //       code,
  //       password,
  //     };

  //     const response = await axios.post('http://localhost:8080/rest/users/verify-code',{
  //       code: data.code,
  //       email: data.email,
  //       newPassword: data.password,
  //     },
  //      {
  //         headers: {
  //           'Content-Type': 'application/json',
  //         },
  //       }); // Replace with your JWT auth new password endpoint

  //     console.log(response.data);
  //     // Handle success or errors as needed
  //   } catch (error) {
  //     console.error(error);
  //     // Handle error appropriately
  //   }
  // }, []);

  const checkAuthenticated = state.user ? 'authenticated' : 'unauthenticated';

  const status = state.loading ? 'loading' : checkAuthenticated;

  const memoizedValue = useMemo(
    () => ({
      user: state.user,
      method: 'jwt',
      loading: status === 'loading',
      authenticated: status === 'authenticated',
      unauthenticated: status === 'unauthenticated',
      userRoles,
      updateRoles,
      login,
      register,
      logout,
      confirmRegister,
      resendCodeRegister,
      forgotPassword,
      // newPassword,
    }),
    [
      userRoles,
      updateRoles,
      login,
      logout,
      register,
      confirmRegister,
      resendCodeRegister,
      forgotPassword,
      state.user,
      status,
    ]
  );

  return <AuthContext.Provider value={memoizedValue}>{children}</AuthContext.Provider>;
}

AuthProvider.propTypes = {
  children: PropTypes.node,
};
