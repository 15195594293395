import PropTypes from 'prop-types';
import { useDropzone } from 'react-dropzone';
import { Box, Typography } from '@mui/material';
import Iconify from '../iconify';
import RejectionFiles from './errors-rejection-files';

export default function UploadBox({
  placeholder,
  helperText,
  maxSize,
  error,
  disabled,
  acceptFile,
  avatarUpload,
  sx,
  ...other
}) {
  const { getRootProps, getInputProps, isDragActive, isDragReject, fileRejections } = useDropzone({
    disabled,
    maxSize: maxSize || 3 * 1024 * 1024, // 3MB limit
    accept: acceptFile, // Dynamically accept file types
    multiple: false,
    ...other,
  });

  const hasError = isDragReject || error || fileRejections.length > 0;

  const formatSize = (size) => {
    if (size >= 1024 * 1024) {
      return `${Math.ceil(size / (1024 * 1024))} MB`; // Round up to nearest whole MB
    }
    if (size >= 1024) {
      return `${Math.ceil(size / 1024)} KB`; // Round up to nearest whole KB
    }
    return `${size} bytes`;
  };

  // Generate Accepted File Types Info dynamically from acceptFile prop
  const getAcceptedFileTypes = () => {
    const fileTypeMap = {
      'application/pdf': 'PDF',
      'application/msword': 'DOC/DOCX',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document': 'DOC/DOCX',
      'image/*': 'Images (e.g., JPG, PNG)',
      'application/zip': 'ZIP',
      'text/plain': 'TEXT',
      'application/vnd.ms-powerpoint': 'PPT',
      'application/vnd.openxmlformats-officedocument.presentationml.presentation': 'PPTX',
      'application/vnd.openxmlformats-officedocument.presentationml.slideshow': 'PPSX',
      'application/vnd.ms-excel': 'XLS', // For older Excel files
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': 'XLSX', // For newer Excel files

    };

    const acceptedTypes = Object.keys(acceptFile || {}).map(
      (type) => fileTypeMap[type] || type.split('/')[1]?.toUpperCase() || type
    );

    return [...new Set(acceptedTypes)].join(', ');
  };

  // Determine the error message

  return (
    <Box sx={{ width: 1, position: 'relative', ...sx }}>
      <Box
        {...getRootProps()}
        sx={{
          m: 0.5,
          width: 64,
          height: 64,
          flexShrink: 0,
          display: 'flex',
          borderRadius: 1,
          cursor: 'pointer',
          alignItems: 'center',
          justifyContent: 'center',
          color: 'text.disabled',
          bgcolor: avatarUpload && fileRejections.length === 0 ? 'transparent' : 'white',
          border: avatarUpload
            ? 'solid 2px rgba(145, 158, 171, 0.2)'
            : 'solid 1px rgba(145, 158, 171, 0.2)',
          '&:hover': {
            opacity: 0.72,
            border: 'solid 1px rgb(0, 0, 0)',
          },
          ...(isDragActive && {
            opacity: 0.72,
          }),
          ...(disabled && {
            opacity: 0.48,
            pointerEvents: 'none',
          }),
          ...(hasError && {
            color: 'error.main',
            bgcolor: 'error.lighter',
            borderColor: 'error.light',
          }),
          ...sx,
        }}
      >
        <input {...getInputProps()} />
        {avatarUpload && fileRejections.length === 0 ? (
          <img
            src={URL.createObjectURL(fileRejections[0])}
            alt="Avatar"
            style={{ width: '100%', height: '100%', borderRadius: '50%' }}
          />
        ) : (
          placeholder || <Iconify icon="eva:cloud-upload-fill" width={28} />
        )}
      </Box>

      <Box sx={{ display: 'flex', gap: 2, mt: 1 }}>
        <Typography variant="caption">
          <strong>Accepted Files:</strong> {getAcceptedFileTypes()}
        </Typography>
        {maxSize && (
          <Typography variant="caption">
            <strong>Max File Size:</strong> {formatSize(maxSize || 3 * 1024 * 1024)}
          </Typography>
        )}
      </Box>

      {helperText && helperText}

      <RejectionFiles
        fileRejections={fileRejections}
        maxSize={maxSize || 3 * 1024 * 1024}
        acceptFile={acceptFile}
      />

      {/* Error Message */}
      {/* {hasError && (
        <Typography
          variant="caption"
          color="error"
          sx={{
            mt: 1,
            display: 'block',
          }}
        >
          <></>
        </Typography>
      )} */}
    </Box>
  );
}

UploadBox.propTypes = {
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  helperText: PropTypes.node,
  placeholder: PropTypes.node,
  sx: PropTypes.object,
  maxSize: PropTypes.number,
  acceptFile: PropTypes.object,
  avatarUpload: PropTypes.bool,
};
