// // ----------------------------------------------------------------------

export function card(theme) {
  return {
    MuiCard: {
      styleOverrides: {
        root: {
          position: 'relative',
          boxShadow: theme.customShadows.card,
          borderRadius: theme.shape.borderRadius * 2,
          zIndex: 0, // Fix Safari overflow: hidden with border radius
        },
      },
    },
    MuiCardHeader: {
      styleOverrides: {
        root: {
          padding: theme.spacing(3, 3, 0),
        },
      },
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          padding: theme.spacing(3),
        },
      },
    },
  };
}

// export function card(theme) {
//   return {
//     MuiCard: {
//       styleOverrides: {
//         root: {
//           position: 'relative',
//           boxShadow: theme.customShadows.card,
//           borderRadius: theme.shape.borderRadius * 2,
//           zIndex: 0, // Fix Safari overflow: hidden with border radius
//         },
//       },
//     },
//     MuiCardHeader: {
//       styleOverrides: {
//         root: {
//           padding: theme.spacing(1, 1, 0), // Reduced padding
//         },
//       },
//     },
//     MuiCardContent: {
//       styleOverrides: {
//         root: {
//           padding: theme.spacing(1.5), // Reduced padding
//         },
//       },
//     },
//   };
// }