// routes
import { paths } from 'src/routes/paths';
// utils
import axios from 'src/utils/axios';

// ----------------------------------------------------------------------

function jwtDecode(token) {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split('')
      .map((c) => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
      .join('')
  );

  return JSON.parse(jsonPayload);
}

// ----------------------------------------------------------------------

export const isValidToken = (token) => {
  console.log(token,"++++++++++++++++++++++++++++++");
  if (!token) {
    return false;
  }

  const decoded = jwtDecode(token);
  console.log(decoded, "decodedddd");

  const currentTime = Date.now() / 1000;
  console.log(currentTime, "timeeeeeeeee");

  return decoded.exp > currentTime;
};

// ----------------------------------------------------------------------

export const tokenExpired = (exp, user,selectedSubscrSite) => {
  // eslint-disable-next-line prefer-const
  let expiredTimer;

  const currentTime = Date.now();

  // Test token expires after 10s
  // const timeLeft = currentTime + 10000 - currentTime; // ~10s
  const timeLeft = exp * 1000 - currentTime;

  clearTimeout(expiredTimer);

  expiredTimer = setTimeout(() => {
    alert('Token expired');

    sessionStorage.removeItem('token');
    sessionStorage.removeItem('selectedSubscrSite');
    const existingUser = sessionStorage.getItem('user');
    if (existingUser) {
      // User exists in session, overwrite with the new user information
      const mergedUser = { ...JSON.parse(existingUser), ...user };
      sessionStorage.setItem('user', JSON.stringify(mergedUser));
    } else {
      // No existing user, store the new user in session
      sessionStorage.setItem('user', JSON.stringify(user));
    }
    // sessionStorage.removeItem('user');

    window.location.href = paths.auth.jwt.login;
  }, timeLeft);
};

// ----------------------------------------------------------------------

export const setSession = (token, user, selectedSubscrSite) => {
  // const existingUser = sessionStorage.getItem('user');

  if (token) {
    sessionStorage.setItem('token', token);
    sessionStorage.setItem('selectedSubscrSite', JSON.stringify(selectedSubscrSite));

    // if (existingUser) {
    //   // User exists in session, overwrite with the new user information
    //   const mergedUser = { ...JSON.parse(existingUser), ...user };
    //   sessionStorage.setItem('user', JSON.stringify(mergedUser));
    // } else {
      // No existing user, store the new user in session
      sessionStorage.setItem('user', JSON.stringify(user));
    // }

    axios.defaults.headers.common.Authorization = `Bearer ${token}`;

    // This function below will handle when the token is expired
    const { exp } = jwtDecode(token);
    tokenExpired(exp);
  } else {
    sessionStorage.removeItem('token');
    // sessionStorage.removeItem('user');

    delete axios.defaults.headers.common.Authorization;
  }
};


// export const setSession = (token, user) => {
//   if (token) {
//     sessionStorage.setItem('token', token);
//     sessionStorage.setItem('user', JSON.stringify(user));

//     axios.defaults.headers.common.Authorization = `Bearer ${token}`;

//     // This function below will handle when token is expired
//     const { exp } = jwtDecode(token); // ~3 days by minimals server
//     tokenExpired(exp);
//   } else {
//     sessionStorage.removeItem('token');
//     sessionStorage.removeItem('user');

//     delete axios.defaults.headers.common.Authorization;
//   }
// };
