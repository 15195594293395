// // ----------------------------------------------------------------------

export function remToPx(value) {
  return Math.round(parseFloat(value) * 16);
}

export function pxToRem(value) {
  return `${value / 16}rem`;
}

export function responsiveFontSizes({ sm, md, lg }) {
  return {
    '@media (min-width:600px)': {
      fontSize: pxToRem(sm),
    },
    '@media (min-width:900px)': {
      fontSize: pxToRem(md),
    },
    '@media (min-width:1200px)': {
      fontSize: pxToRem(lg),
    },
  };
}

const primaryFont = 'Public Sans, sans-serif'; // Google Font
// const secondaryFont = 'CircularStd, sans-serif'; // Local Font

// // ----------------------------------------------------------------------

// export const typography = {
//   fontFamily: primaryFont,
//   fontWeightRegular: 400,
//   fontWeightMedium: 500,
//   fontWeightSemiBold: 600,
//   fontWeightBold: 700,
//   h1: {
//     fontWeight: 800,
//     lineHeight: 80 / 64,
//     fontSize: pxToRem(40),
//     ...responsiveFontSizes({ sm: 52, md: 58, lg: 64 }),
//   },
//   h2: {
//     fontWeight: 800,
//     lineHeight: 64 / 48,
//     fontSize: pxToRem(32),
//     ...responsiveFontSizes({ sm: 40, md: 44, lg: 48 }),
//   },
//   h3: {
//     fontWeight: 700,
//     lineHeight: 1.5,
//     fontSize: pxToRem(24),
//     ...responsiveFontSizes({ sm: 26, md: 30, lg: 32 }),
//   },
//   h4: {
//     fontWeight: 700,
//     lineHeight: 1.5,
//     fontSize: pxToRem(20),
//     ...responsiveFontSizes({ sm: 20, md: 24, lg: 24 }),
//   },
//   h5: {
//     fontWeight: 700,
//     lineHeight: 1.5,
//     fontSize: pxToRem(18),
//     ...responsiveFontSizes({ sm: 19, md: 20, lg: 20 }),
//   },
//   h6: {
//     fontWeight: 700,
//     lineHeight: 28 / 18,
//     fontSize: pxToRem(17),
//     ...responsiveFontSizes({ sm: 18, md: 18, lg: 18 }),
//   },
//   subtitle1: {
//     fontWeight: 600,
//     lineHeight: 1.5,
//     fontSize: pxToRem(16),
//   },
//   subtitle2: {
//     fontWeight: 600,
//     lineHeight: 22 / 14,
//     fontSize: pxToRem(14),
//   },
//   body1: {
//     lineHeight: 1.5,
//     fontSize: pxToRem(16),
//   },
//   body2: {
//     lineHeight: 22 / 14,
//     fontSize: pxToRem(14),
//   },
//   caption: {
//     lineHeight: 1.5,
//     fontSize: pxToRem(12),
//   },
//   overline: {
//     fontWeight: 700,
//     lineHeight: 1.5,
//     fontSize: pxToRem(12),
//     textTransform: 'uppercase',
//   },
//   button: {
//     fontWeight: 700,
//     lineHeight: 24 / 14,
//     fontSize: pxToRem(14),
//     textTransform: 'unset',
//   },
// };

// export const typography = {
//   fontFamily: primaryFont,
//   fontWeightRegular: 400,
//   fontWeightMedium: 500,
//   fontWeightSemiBold: 600,
//   fontWeightBold: 700,
//   h1: {
//     fontWeight: 800,
//     lineHeight: 80 / 64,
//     fontSize: pxToRem(28), // Reduced from 40
//     ...responsiveFontSizes({ sm: 42, md: 46, lg: 52 }), // Adjusted for smaller screens
//   },
//   h2: {
//     fontWeight: 800,
//     lineHeight: 64 / 48,
//     fontSize: pxToRem(26), // Reduced from 32
//     ...responsiveFontSizes({ sm: 34, md: 38, lg: 42 }),
//   },
//   h3: {
//     fontWeight: 700,
//     lineHeight: 1.5,
//     fontSize: pxToRem(20), // Reduced from 24
//     ...responsiveFontSizes({ sm: 22, md: 26, lg: 28 }),
//   },
//   h4: {
//     fontWeight: 700,
//     lineHeight: 1.5,
//     fontSize: pxToRem(18), // Reduced from 20
//     ...responsiveFontSizes({ sm: 18, md: 20, lg: 20 }),
//   },
//   h5: {
//     fontWeight: 700,
//     lineHeight: 1.5,
//     fontSize: pxToRem(16), // Reduced from 18
//     ...responsiveFontSizes({ sm: 17, md: 18, lg: 18 }),
//   },
//   h6: {
//     fontWeight: 700,
//     lineHeight: 28 / 16, // Adjusted line height
//     fontSize: pxToRem(14), // Reduced from 17
//     ...responsiveFontSizes({ sm: 15, md: 16, lg: 16 }),
//   },
//   subtitle1: {
//     fontWeight: 600,
//     lineHeight: 1.5,
//     fontSize: pxToRem(14), // Reduced from 16
//   },
//   subtitle2: {
//     fontWeight: 600,
//     lineHeight: 22 / 12, // Adjusted line height
//     fontSize: pxToRem(12), // Reduced from 14
//   },
//   body1: {
//     lineHeight: 1.5,
//     fontSize: pxToRem(14), // Reduced from 16
//   },
//   body2: {
//     lineHeight: 22 / 12, // Adjusted line height
//     fontSize: pxToRem(12), // Reduced from 14
//   },
//   caption: {
//     lineHeight: 1.5,
//     fontSize: pxToRem(10), // Reduced from 12
//   },
//   overline: {
//     fontWeight: 700,
//     lineHeight: 1.5,
//     fontSize: pxToRem(10), // Reduced from 12
//     textTransform: 'uppercase',
//   },
//   button: {
//     fontWeight: 700,
//     lineHeight: 20 / 12, // Adjusted line height
//     fontSize: pxToRem(12), // Reduced from 14
//     textTransform: 'unset',
//   },
// };


export const typography = {
  fontFamily: primaryFont,
  fontWeightRegular: 400,
  fontWeightMedium: 500,
  fontWeightSemiBold: 600,
  fontWeightBold: 700,

  h1: {
    fontWeight: 800,
    lineHeight: 80 / 64,
    fontSize: pxToRem(24), // Reduced further from 32
    ...responsiveFontSizes({ sm: 30, md: 34, lg: 38 }), // Smaller scaling
  },
  h2: {
    fontWeight: 800,
    lineHeight: 64 / 48,
    fontSize: pxToRem(20), // Reduced further from 26
    ...responsiveFontSizes({ sm: 28, md: 30, lg: 32 }),
  },
  h3: {
    fontWeight: 700,
    lineHeight: 1.5,
    fontSize: pxToRem(16), // Reduced further from 20
    ...responsiveFontSizes({ sm: 18, md: 20, lg: 22 }),
  },
  h4: {
    fontWeight: 700,
    lineHeight: 1.5,
    fontSize: pxToRem(14), // Reduced further from 18
    ...responsiveFontSizes({ sm: 15, md: 16, lg: 16 }),
  },
  h5: {
    fontWeight: 700,
    lineHeight: 1.5,
    fontSize: pxToRem(12), // Reduced further from 16
    ...responsiveFontSizes({ sm: 13, md: 14, lg: 14 }),
  },
  h6: {
    fontWeight: 700,
    lineHeight: 24 / 12, // Adjusted line height
    fontSize: pxToRem(10.5), // Reduced further from 14
    ...responsiveFontSizes({ sm: 11, md: 12, lg: 12 }),
  },
  subtitle1: {
    fontWeight: 600,
    lineHeight: 1.5,
    fontSize: pxToRem(12), // Reduced further from 14
  },
  subtitle2: {
    fontWeight: 600,
    lineHeight: 20 / 10, // Adjusted line height
    fontSize: pxToRem(10.5), // Reduced further from 12 // subtitle is a chimp for the Filter Results
  },
  body1: {
    lineHeight: 1.5,
    fontSize: pxToRem(11), // Reduced further from 14  // for breadcrumbs
  },
  body2: {
    lineHeight: 20 / 10, // Adjusted line height
    fontSize: pxToRem(12), // Reduced further from 12  // for Menu-items Drawer
  },
  caption: {
    lineHeight: 1.5,
    fontSize: pxToRem(10.5), // Reduced further from 10
  },
  overline: {
    fontWeight: 700,
    lineHeight: 1.5,
    fontSize: pxToRem(8), // Reduced further from 10
    textTransform: 'uppercase',
  },
  button: {
    fontWeight: 700,
    lineHeight: 18 / 10, // Adjusted line height
    fontSize: pxToRem(10.8), // Reduced further from 12
    textTransform: 'unset',
  },
};
