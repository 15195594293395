
// import { menuItem } from '../../css';

// // ----------------------------------------------------------------------

// export function menu(theme) {
//   return {
//     MuiMenuItem: {
//       styleOverrides: {
//         root: {
//           ...menuItem(theme),
//         },
//       },
//     },
//   };
// }

import { menuItem } from '../../css';

// ----------------------------------------------------------------------

export function menu(theme) {
  return {
    MuiMenuItem: {
      styleOverrides: {
        root: {
          ...menuItem(theme),
          fontSize: 10.5, // Minimized font size for menu items
          padding: theme.spacing(0.5), // Minimal padding for compactness
        },
      },
    },
  };
}

