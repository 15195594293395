// import PropTypes from 'prop-types';
// import { useFormContext, Controller } from 'react-hook-form';
// // @mui
// import TextField from '@mui/material/TextField';
// import Autocomplete from '@mui/material/Autocomplete';
// import ListboxComponent from 'src/components/virtualized-autocomplete/autocomplete-virtualized';

// // ----------------------------------------------------------------------

// export default function RHFAutocomplete({ name, label, placeholder, helperText, ...other }) {
//   const { control, setValue } = useFormContext();

//   return (
//     <Controller
//       name={name}
//       control={control}
//       render={({ field, fieldState: { error } }) => (
//         <Autocomplete
//           {...field}
//           onChange={(event, newValue) => setValue(name, newValue, { shouldValidate: true })}
//           renderInput={(params) => (
//             <TextField
//               label={label}
//               placeholder={placeholder}
//               error={!!error}
//               helperText={error ? error?.message : helperText}
//               {...params}
//             />
//           )}

//           {...other}
//         />
//       )}
//     />
//   );
// }

// RHFAutocomplete.propTypes = {
//   helperText: PropTypes.string,
//   label: PropTypes.string,
//   name: PropTypes.string,
//   placeholder: PropTypes.string,
// };
// import React, { useState } from 'react';
// import PropTypes from 'prop-types';
// import { useFormContext, Controller } from 'react-hook-form';
// // @mui
// import TextField from '@mui/material/TextField';
// import Autocomplete from '@mui/material/Autocomplete';
// import IconButton from '@mui/material/IconButton';
// import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
// import Box from '@mui/material/Box';
// import Dialog from '@mui/material/Dialog';
// import DialogActions from '@mui/material/DialogActions';
// import DialogContent from '@mui/material/DialogContent';
// import DialogTitle from '@mui/material/DialogTitle';
// import Button from '@mui/material/Button';

// // ----------------------------------------------------------------------

// export default function RHFAutocomplete({
//   name,
//   label,
//   placeholder,
//   helperText,
//   showPlusIcon,
//   ...other
// }) {
//   const { control, setValue } = useFormContext();
//   const [open, setOpen] = useState(false);
//   const [newDialogOpen, setNewDialogOpen] = useState(false); // State for the second dialog
//   const [dialogTitle, setDialogTitle] = useState(''); // State for the dynamic dialog title

//   // Handlers for dialogs
//   const handleOpen = () => setOpen(true);
//   const handleClose = () => setOpen(false);

//   // Handler for the "Add New" button in the existing dialog
//   const handleAddNew = () => {
//     const title = typeof label === 'string' ? label : name; // Ensure title is a string
//     setDialogTitle(`Add New ${title} Data To Master`);
//     handleClose(); // Close the first dialog
//     setNewDialogOpen(true); // Open the second dialog
//   };

//   const handleNewDialogClose = () => setNewDialogOpen(false);

//   return (
//     <Box sx={{ position: 'relative', display: 'inline-block', width: '100%' }}>
//       <Controller
//         name={name}
//         control={control}
//         render={({ field, fieldState: { error } }) => (
//           <>
//             {/* Autocomplete Dropdown Field */}
//             <Autocomplete
//               {...field}
//               onChange={(event, newValue) => setValue(name, newValue, { shouldValidate: true })}
//               renderInput={(params) => (
//                 <TextField
//                   label={label}
//                   placeholder={placeholder}
//                   error={!!error}
//                   helperText={error ? error?.message : helperText}
//                   {...params}
//                 />
//               )}
//               {...other}
//             />

//             {/* Conditionally Rendering the Plus Icon */}
//             {showPlusIcon && (
//               <Box
//                 sx={{
//                   position: 'absolute',
//                   top: '50%',
//                   right: 50,
//                   transform: 'translateY(-50%)',
//                   display: 'flex',
//                   alignItems: 'center',
//                   gap: '4px', // Space between icons
//                   zIndex: 1,
//                 }}
//               >
//                 <IconButton
//                   color="primary"
//                   sx={{
//                     position: 'absolute',
//                     top: '50%',
//                     left: '-45px',
//                     transform: 'translateY(-50%)',
//                     zIndex: 2,
//                     backgroundColor: 'white',
//                     borderRadius: '50%',
//                     border: '1px solid lightgray',
//                     padding: 0,
//                     width: 32,
//                     height: 32,
//                     display: 'flex',
//                     justifyContent: 'center',
//                     alignItems: 'center',
//                   }}
//                   onClick={handleOpen}
//                 >
//                   <AddCircleOutlineIcon fontSize="small" />
//                 </IconButton>
//               </Box>
//             )}

//             {/* First Dialog Popup */}
//             <Dialog open={open} onClose={handleClose}>
//               <DialogTitle>Add New Value</DialogTitle>
//               <DialogContent>
//                 If you want to add a new value to this master, please click on Add New
//               </DialogContent>
//               <DialogActions>
//                 <Button onClick={handleClose} color="error">
//                   Cancel
//                 </Button>
//                 <Button onClick={handleAddNew} color="secondary">
//                   Add New
//                 </Button>
//               </DialogActions>
//             </Dialog>

//             {/* Second Dialog Popup (appears after "Add New" click) */}
//             <Dialog
//               open={newDialogOpen}
//               onClose={handleNewDialogClose}
//               maxWidth="sm" // Set the maximum width of the dialog
//               fullWidth // Make it occupy the full width of the max width
//               sx={{
//                 '& .MuiDialog-paper': {
//                   minWidth: '600px', // Adjust the width of the dialog
//                   padding: '20px', // Add some padding
//                 },
//               }}
//             >
//               <DialogTitle
//                 sx={{
//                   fontSize: '1.5rem', // Increase the font size
//                   fontWeight: 'bold', // Make the title bold
//                 }}
//               >
//                 {dialogTitle}
//               </DialogTitle>
//               <DialogContent
//                 sx={{
//                   fontSize: '1rem', // Increase content font size
//                 }}
//               >
//                 <Box>
//                   <TextField
//                     fullWidth
//                     label="Enter New Value"
//                     placeholder="Type here"
//                     variant="outlined"
//                     sx={{
//                       mt: 2,
//                       '& .MuiInputBase-input': {
//                         fontSize: '1.2rem', // Increase the font size of the input text
//                       },
//                       '& .MuiInputLabel-root': {
//                         fontSize: '1.1rem', // Increase the font size of the label
//                       },
//                     }}
//                   />
//                 </Box>
//               </DialogContent>
//               <DialogActions>
//                 <Button
//                   onClick={handleNewDialogClose}
//                   color="error"
//                   sx={{ fontSize: '1rem' }} // Adjust the button text size
//                 >
//                   Cancel
//                 </Button>
//                 <Button
//                   onClick={handleNewDialogClose}
//                   color="secondary"
//                   sx={{ fontSize: '1rem' }} // Adjust the button text size
//                 >
//                   Submit
//                 </Button>
//               </DialogActions>
//             </Dialog>
//           </>
//         )}
//       />
//     </Box>
//   );
// }

// RHFAutocomplete.propTypes = {
//   helperText: PropTypes.string,
//   label: PropTypes.string,
//   name: PropTypes.string,
//   placeholder: PropTypes.string,
//   showPlusIcon: PropTypes.bool, // Add this prop to allow showing the plus icon conditionally
// };

// RHFAutocomplete.defaultProps = {
//   showPlusIcon: false, // Default value for the showPlusIcon prop
// };

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useFormContext, Controller } from 'react-hook-form';
// @mui
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import IconButton from '@mui/material/IconButton';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import axios from 'axios';
import { getCurrentProject, getCurrentServer, getMandEndPoint } from 'src/helper/server-address';

export default function RHFAutocomplete({
  name,
  label,
  placeholder,
  helperText,
  showPlusIcon,
  ...other
}) {
  const { control, setValue } = useFormContext();
  const [open, setOpen] = useState(false);
  const [newDialogOpen, setNewDialogOpen] = useState(false); // State for the second dialog
  const [dialogTitle, setDialogTitle] = useState(''); // State for the dynamic dialog title
  const [newValues, setNewValues] = useState(''); // State for storing multiple new values
  const [warningMessage, setWarningMessage] = useState(''); // State for the warning message

  // Handlers for dialogs
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  // Handler for the "Add New" button in the existing dialog
  const handleAddNew = () => {
    const title = typeof label === 'string' ? label : name; // Ensure title is a string
    setDialogTitle(`Add New ${title} Data To Master`);
    handleClose(); // Close the first dialog
    setNewDialogOpen(true); // Open the second dialog
  };

  const handleNewDialogClose = () => setNewDialogOpen(false);

  // Handle input change for multiple values
  const handleInputChange = (event) => {
    setNewValues(event.target.value);
  };

  // Handle Submit function to send email using your API
  const handleSubmit = async () => {
    const title = typeof label === 'string' ? label : name; // Ensure title is a string

    // Check if newValues is empty
    if (!newValues.trim()) {
      setWarningMessage('Please enter at least one value.'); // Set the warning message
      return;
    }

    // Clear any previous warning
    setWarningMessage('');

    // Split the input by commas, new lines, or spaces to handle multiple values
    const valuesArray = newValues
      .split(/[\n,;\s]+/) // Split by newlines, commas, semicolons, or spaces
      .map(value => value.trim())
      .filter(value => value);

      const sessionData = JSON.parse(sessionStorage.getItem("selectedSubscrSite"));
      const subscrSiteId = sessionData?.subscrSite?.subscrSiteId;
    
      console.log("Session SubscrSiteId:", subscrSiteId);


    // Construct the email body with multiple values
    const emailBody = `
      <html>
        <p>Dear [Superadmin Name],</p>

        <p>I hope this email finds you well.</p>

        <p>We would like to request your approval for new value(s) that have been added to the <b>${title}</b> dropdown list in the application. Kindly review the added value(s) and approve their inclusion in the master list.</p>
        <p>Below are the details of the new data:</p>
        <ul>
          <li><b>Field Name:</b> ${title}</li>
          <li><b>Added Value(s):</b> ${valuesArray.join(', ')}</li>
          <li><b>Date Added:</b> ${new Date().toLocaleDateString()}</li> <!-- Dynamically set the current date -->
        </ul>

        <p>Please review the data and approve the addition to the master list. If you have any questions or need further information, feel free to reach out.</p>

        <p>Looking forward to your approval.</p>

        <p>Best regards,</p>
        <p>[Your Name]</p>
        <p>[Your Position]</p>
        <p>[Your Company Name]</p>
        <p>[Contact Information]</p>

        <p>This is a system-generated email, please do not reply.</p>
        <h4>Thank You!</h4>
      </html>
    `;

    const formData = new FormData();
    formData.append('subject', `Approval Request: Add New ${title} Data to the Master`);
    formData.append('body', emailBody);
    formData.append('to', ["vaibhav.wadkar@cybermeru.com"]); // Replace with actual recipient

    try {
      // Use the provided API format
      const token = sessionStorage.getItem('token');
   
      await axios.post(
        `${getCurrentServer()}/${getCurrentProject()}/${getMandEndPoint()}/superadmin/emails/send`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`, // Include the token for authentication
          },
        }
      );
      handleNewDialogClose(); // Close the dialog on successful email send
    } catch (error) {
      alert("Failed to send email. Please try again.");
    }
  };

  return (
    <Box sx={{ position: 'relative', display: 'inline-block', width: '100%' }}>
      <Controller
        name={name}
        control={control}
        render={({ field, fieldState: { error } }) => (
          <>
            {/* Autocomplete Dropdown Field */}
            <Autocomplete
              {...field}
              onChange={(event, newValue1) => setValue(name, newValue1, { shouldValidate: true })}
              renderInput={(params) => (
                <TextField
                  label={label}
                  placeholder={placeholder}
                  error={!!error}
                  helperText={error ? error?.message : helperText}
                  {...params}
                />
              )}
              {...other}
            />

            {/* Conditionally Rendering the Plus Icon */}
            {showPlusIcon && (
              <Box
                sx={{
                  position: 'absolute',
                  top: '50%',
                  right: 50,
                  transform: 'translateY(-50%)',
                  display: 'flex',
                  alignItems: 'center',
                  gap: '4px', // Space between icons
                  zIndex: 1,
                }}
              >
                <IconButton
                  color="primary"
                  sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '-45px',
                    transform: 'translateY(-50%)',
                    zIndex: 2,
                    backgroundColor: 'white',
                    borderRadius: '50%',
                    padding: 0,
                    width: 32,
                    height: 32,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                  onClick={handleOpen}
                >
                  <AddCircleOutlineIcon fontSize="small" />
                </IconButton>
              </Box>
            )}

            {/* First Dialog Popup */}
            <Dialog open={open} onClose={handleClose}>
              <DialogTitle>Add New Value</DialogTitle>
              <DialogContent>
                If you want to add a new value to this master, please click on Add New
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose} color="error">
                  Cancel
                </Button>
                <Button onClick={handleAddNew} color="secondary">
                  Add New
                </Button>
              </DialogActions>
            </Dialog>

            {/* Second Dialog Popup (appears after "Add New" click) */}
            <Dialog
              open={newDialogOpen}
              onClose={handleNewDialogClose}
              maxWidth="sm" // Set the maximum width of the dialog
              fullWidth // Make it occupy the full width of the max width
              sx={{
                '& .MuiDialog-paper': {
                  minWidth: '600px', // Adjust the width of the dialog
                  padding: '20px', // Add some padding
                },
              }}
            >
              <DialogTitle
                sx={{
                  fontSize: '1.5rem', // Increase the font size
                  fontWeight: 'bold', // Make the title bold
                }}
              >
                {dialogTitle}
              </DialogTitle>
              <DialogContent
                sx={{
                  fontSize: '1rem', // Increase content font size
                }}
              >
                <Box>
                  <TextField
                    fullWidth
                    label="Enter New Values (Separate by commas, new lines)"
                    placeholder="Type here"
                    variant="outlined"
                    value={newValues}
                    onChange={handleInputChange}
                    multiline
                    rows={4}
                    sx={{
                      mt: 2,
                      '& .MuiInputBase-input': {
                        fontSize: '1.2rem', // Increase the font size of the input text
                      },
                      '& .MuiInputLabel-root': {
                        fontSize: '1.1rem', // Increase the font size of the label
                      },
                    }}
                  />
                </Box>

                {/* Display the warning message if input is empty */}
                {warningMessage && (
                  <div style={{ color: 'red', marginTop: '10px' }}>
                    {warningMessage}
                  </div>
                )}
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={handleNewDialogClose}
                  color="error"
                  sx={{ fontSize: '1rem' }} // Adjust the button text size
                >
                  Cancel
                </Button>
                <Button
                  onClick={handleSubmit}
                  color="secondary"
                  sx={{ fontSize: '1rem' }} // Adjust the button text size
                >
                  Submit
                </Button>
              </DialogActions>
            </Dialog>
          </>
        )}
      />
    </Box>
  );
}

RHFAutocomplete.propTypes = {
  helperText: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  showPlusIcon: PropTypes.bool, // Add this prop to allow showing the plus icon conditionally
};

RHFAutocomplete.defaultProps = {
  showPlusIcon: false, // Default value for the showPlusIcon prop
};
