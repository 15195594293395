import { useState, useEffect } from 'react';
import {
  Stack,
  Badge,
  IconButton,
  Drawer,
  Divider,
  Tabs,
  Tab,
  Typography,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Tooltip,
  Button,
  ListItemButton,
  ListItemAvatar,
} from '@mui/material';
import { formatDistanceToNow } from 'date-fns';
import Iconify from 'src/components/iconify';
import { useSnackbar } from 'src/components/snackbar';
import { useRouter } from 'src/routes/hooks';
import { paths } from 'src/routes/paths';
import Label from 'src/components/label';
import Scrollbar from 'src/components/scrollbar';
import { useBoolean } from 'src/hooks/use-boolean';
import SockJS from 'sockjs-client';
// eslint-disable-next-line import/no-unresolved, import/no-extraneous-dependencies
import { Client } from '@stomp/stompjs';
import axios from 'axios';
import { getCurrentProject, getCurrentServer, getMandEndPoint } from 'src/helper/server-address';

export default function NotificationsPopover() {
  const router = useRouter();
  const { enqueueSnackbar } = useSnackbar();
  const [notifications, setNotifications] = useState([]);
  const [filteredNotifications, setFilteredNotifications] = useState([]);
  const [currentTab, setCurrentTab] = useState('unread');
  const [showAllNotifications, setShowAllNotifications] = useState(false);
  const token = sessionStorage.getItem('token');
  const user = JSON.parse(sessionStorage.getItem('user'));
  const drawer = useBoolean();
  const totalUnRead = notifications.filter(
    (notification) => notification.status === 'Unread'
  ).length;

  const TABS = [
    { value: 'unread', label: 'Unread', count: totalUnRead },
    {
      value: 'read',
      label: 'Read',
      count: notifications.filter((notification) => notification.status === 'Read').length,
    },
  ];

  // Fetch initial notifications on component mount
  useEffect(() => {
    const fetchNotifications = async () => {
      try {
        const response = await axios.get(
          `${getCurrentServer()}/${getCurrentProject()}/${getMandEndPoint()}/notifications/user/${user.userId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (response.status === 200) {
          const data = response.data;

          const formattedNotifications = data.map((notification) => ({
            id: notification.id,
            message: notification.message,
            status: notification.read ? 'Read' : 'Unread', // mapping read status
            timestamp: notification.createdDate, // Ensure createdAt field exists
          }));

          setNotifications(formattedNotifications);
        } else {
          enqueueSnackbar('Failed to load notifications', { variant: 'error' });
        }
      } catch (error) {
        enqueueSnackbar('Error fetching notifications', { variant: 'error' });
        console.error('Error fetching notifications:', error);
      }
    };

    fetchNotifications();
  }, [enqueueSnackbar, user.userId, token]);

  // Filter notifications based on currentTab
  useEffect(() => {
    const filterNotifications = () => {
      switch (currentTab) {
        case 'unread':
          return notifications.filter((notification) => notification.status === 'Unread');
        case 'read':
          return notifications.filter((notification) => notification.status === 'Read');
        default:
          return notifications;
      }
    };
    setFilteredNotifications(filterNotifications());
  }, [currentTab, notifications]);

  // WebSocket connection and handling new notifications
  useEffect(() => {
    const stompClient = new Client({
      webSocketFactory: () => {
        const socketUrl = `${getCurrentServer()}/${getCurrentProject()}/${getMandEndPoint()}/ws?token=${token}`;
        return new SockJS(socketUrl);
      },
      onConnect: () => {
        stompClient.subscribe(`/topic/user/${user.userId}`, (data) => {
          const message = JSON.parse(data.body);
          const newNotification = {
            id: message.id,
            message: message.message,
            status: 'Unread',
            timestamp: new Date(),
          };
          if (newNotification.message && newNotification.status === 'Unread') {
            enqueueSnackbar('New Notification!', { variant: 'info', autoHideDuration: 10000 });
            setNotifications((prevNotifications) => [newNotification, ...prevNotifications]);
          }
        });
      },
      onDisconnect: () => {
        console.log('Disconnected from WebSocket');
      },
      onStompError: (error) => {
        console.error('STOMP Error:', error);
      },
    });

    stompClient.activate();
    return () => stompClient.deactivate();
  }, [enqueueSnackbar, user.userId, token]);

  const displayedNotifications = showAllNotifications
    ? filteredNotifications
    : filteredNotifications.slice(0, 10);

  const handleNotificationClick = async (notification) => {
    try {
      const response = await axios.put(
        `${getCurrentServer()}/${getCurrentProject()}/${getMandEndPoint()}/notifications/read/${notification.id}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200) {
        const updatedNotifications = notifications.map((item) =>
          item.id === notification.id ? { ...item, status: 'Read' } : item
        );
        setNotifications(updatedNotifications);

        // Handle routing based on screenId (if applicable)
        if (notification.screenId && notification.screenId.screenNm === 'Item Requisition') {
          router.push(paths.dashboard.inventory.ItemRequisition);
        }
      } else {
        enqueueSnackbar('Failed to mark as read', { variant: 'error' });
      }
    } catch (error) {
      enqueueSnackbar('Error marking notification as read', { variant: 'error' });
    }
  };

  const handleChangeTab = (event, newValue) => {
    setCurrentTab(newValue);
  };

  const handleMarkAllAsRead = async () => {
    try {
      const response = await axios.put(
        `${getCurrentServer()}/${getCurrentProject()}/${getMandEndPoint()}/notifications/read/user/${user.userId}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200) {
        const updatedNotifications = notifications.map((notification) => ({
          ...notification,
          status: 'Read',
        }));
        setNotifications(updatedNotifications);
      } else {
        enqueueSnackbar('Failed to mark all as read', { variant: 'error' });
      }
    } catch (error) {
      enqueueSnackbar('Error marking all notifications as read', { variant: 'error' });
    }
  };

  const handleDeleteNotification = async (event, notificationId) => {
    event.stopPropagation();
    try {
      const response = await axios.delete(
        `${getCurrentServer()}/${getCurrentProject()}/${getMandEndPoint()}/notifications/delete/${notificationId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200) {
        const updatedNotifications = notifications.filter(
          (notification) => notification.id !== notificationId
        );
        setNotifications(updatedNotifications);
      } else {
        enqueueSnackbar('Failed to delete notification', { variant: 'error' });
      }
    } catch (error) {
      enqueueSnackbar('Error deleting notification', { variant: 'error' });
    }
  };
  const handleDeleteAllNotifications = async () => {
    try {
      const response = await axios.delete(
        `${getCurrentServer()}/${getCurrentProject()}/${getMandEndPoint()}/notifications/delete/user/${user.userId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200) {
        setNotifications([]); // Clear the notifications after successful deletion
        enqueueSnackbar('All notifications deleted successfully', { variant: 'success' });
      } else {
        enqueueSnackbar('Failed to delete notifications', { variant: 'error' });
      }
    } catch (error) {
      enqueueSnackbar('Error deleting notifications', { variant: 'error' });
      console.error('Error deleting notifications:', error);
    }
  };

  const renderHead = (
    <Stack
      direction="row"
      alignItems="center"
      sx={{
        py: 1.5,
        pl: 2.5,
        pr: 1,
        backgroundColor: '#fffff',
        transition: 'all 0.3s ease',
      }}
    >
      <Typography variant="h6" sx={{ flexGrow: 1 }}>
        Notifications
      </Typography>
      <Tooltip title="Mark all as read">
        <IconButton onClick={handleMarkAllAsRead} color="primary">
          <Iconify icon="eva:done-all-fill" />
        </IconButton>
      </Tooltip>
      <IconButton onClick={handleDeleteAllNotifications} sx={{ color: '#f1512e' }}>
        <Tooltip title="Delete all notifications">
          <Iconify icon="mdi:delete-forever" width={20} height={20} />
        </Tooltip>
      </IconButton>

      <IconButton onClick={drawer.onFalse} sx={{ color: '#f1512e' }}>
        <Tooltip title="Close notifications">
          <Iconify icon="mingcute:close-line" />
        </Tooltip>
      </IconButton>
    </Stack>
  );

  const renderTabs = (
    <Tabs
      value={currentTab}
      onChange={handleChangeTab}
      sx={{ display: 'flex', justifyContent: 'space-between' }}
    >
      {TABS.map((tab) => (
        <Tab
          key={tab.value}
          value={tab.value}
          label={tab.label}
          icon={
            <Label
              variant={tab.value === currentTab ? 'filled' : 'soft'}
              color={tab.value === 'unread' ? 'info' : 'success'}
              sx={{ fontSize: '0.85rem', padding: '2px 8px' }}
            >
              {tab.count}
            </Label>
          }
          sx={{
            '&.Mui-selected': {
              backgroundColor: '#eeeeee',
              borderRadius: '8px',
              color: '#1976d2',
              transition: 'all 0.2s ease',
            },
            '&.MuiTab-root': {
              padding: '0 16px',
              fontSize: '0.9rem',
              fontWeight: 'bold',
            },
          }}
        />
      ))}
    </Tabs>
  );

  const renderList = (
    <Scrollbar>
      {displayedNotifications.length > 0 ? (
        <List disablePadding>
          {displayedNotifications.map((notification) => (
            <ListItemButton
              key={notification.id}
              disableRipple
              sx={{
                p: 2.5,
                alignItems: 'flex-start',
                borderBottom: (theme) => `dashed 1px ${theme.palette.divider}`,
                position: 'relative',
              }}
              onClick={() => handleNotificationClick(notification)}
            >
              <ListItemAvatar>
                <Stack
                  alignItems="center"
                  justifyContent="center"
                  sx={{
                    width: 30,
                    height: 30,
                    borderRadius: '50%',
                    backgroundColor: notification.status === 'Unread' ? '#e3f2fd' : 'transparent',
                  }}
                >
                  <Iconify
                    icon={notification.status === 'Unread' ? 'mdi:bell' : 'mdi:bell-off'}
                    width={20}
                    height={20}
                    color={notification.status === 'Unread' ? '#1976d2' : '#999'}
                  />
                </Stack>
              </ListItemAvatar>

              <ListItemText
                disableTypography
                primary={notification.message}
                secondary={
                  <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                    sx={{ typography: 'caption', color: 'text.disabled' }}
                  >
                    <Typography variant="caption" sx={{ marginRight: 1 }}>
                      {formatDistanceToNow(new Date(notification.timestamp), { addSuffix: true })}
                    </Typography>

                    <Tooltip title="Delete Notification">
                      <Iconify
                        icon="mdi:delete"
                        width={20}
                        height={20}
                        color="#f44336"
                        onClick={(e) => handleDeleteNotification(e, notification.id)}
                        sx={{
                          cursor: 'pointer',
                          '&:hover': {
                            color: '#d32f2f',
                          },
                        }}
                      />
                    </Tooltip>
                  </Stack>
                }
              />
            </ListItemButton>
          ))}
        </List>
      ) : (
        <Typography variant="body2" sx={{ p: 2, textAlign: 'center', color: '#888' }}>
          No notifications available
        </Typography>
      )}

      {!showAllNotifications && filteredNotifications.length > 10 && (
        <Button
          sx={{
            width: '100%',
            marginTop: 2,
            backgroundColor: '#1976d2',
            color: 'white',
            borderRadius: 1.5,
            '&:hover': {
              backgroundColor: '#1565c0',
            },
          }}
          onClick={() => setShowAllNotifications(true)}
        >
          See All
        </Button>
      )}
    </Scrollbar>
  );

  return (
    <>
      <IconButton color="default" onClick={drawer.onTrue}>
        <Badge
          badgeContent={totalUnRead}
          color="error"
          sx={{
            transition: 'all 0.3s ease',
            '& .MuiBadge-dot': {
              backgroundColor: '#d32f2f',
              animation: 'pulse 1s infinite',
            },
          }}
        >
          <Iconify icon="mdi:notifications-active" width={24} height={26} color="#ffbe4d" />
        </Badge>
      </IconButton>

      <Drawer
        open={drawer.value}
        onClose={() => {
          drawer.onFalse();
          setShowAllNotifications(false);
        }}
        anchor="right"
        slotProps={{
          backdrop: { invisible: true },
        }}
        PaperProps={{
          sx: {
            width: 1,
            maxWidth: 420,
            padding: 2,
            boxShadow: 3,
            borderRadius: '8px 0 0 8px',
            transition: 'all 0.3s ease',
          },
        }}
      >
        {renderHead}
        {renderTabs}
        <Divider />
        {renderList}
      </Drawer>
    </>
  );
}

// import { useState, useEffect } from 'react';
// import {
//   Stack,
//   Badge,
//   IconButton,
//   Drawer,
//   Divider,
//   Tabs,
//   Tab,
//   Typography,
//   List,
//   ListItem,
//   ListItemText,
//   ListItemIcon,
//   Tooltip,
//   Button,
//   ListItemButton,
//   ListItemAvatar,
// } from '@mui/material';
// import { formatDistanceToNow } from 'date-fns';
// import Iconify from 'src/components/iconify';
// import { useSnackbar } from 'src/components/snackbar';
// import { useRouter } from 'src/routes/hooks';
// import { paths } from 'src/routes/paths';
// import Label from 'src/components/label';
// import Scrollbar from 'src/components/scrollbar';
// import { useBoolean } from 'src/hooks/use-boolean';
// import SockJS from 'sockjs-client';
// // eslint-disable-next-line import/no-extraneous-dependencies
// import { Client } from '@stomp/stompjs';
// import axios from 'axios';

// export default function NotificationsPopover() {
//   const router = useRouter();
//   const { enqueueSnackbar } = useSnackbar();
//   const [notifications, setNotifications] = useState([]);
//   const [filteredNotifications, setFilteredNotifications] = useState([]);
//   const [currentTab, setCurrentTab] = useState('unread');
//   const [showAllNotifications, setShowAllNotifications] = useState(false); // New state for "See All"
//   const token = sessionStorage.getItem('token');
//   const user = JSON.parse(sessionStorage.getItem('user'));
//   const drawer = useBoolean();
//   const totalUnRead = notifications.filter(
//     (notification) => notification.status === 'Unread'
//   ).length;

//   const TABS = [
//     { value: 'unread', label: 'Unread', count: totalUnRead },
//     {
//       value: 'read',
//       label: 'Read',
//       count: notifications.filter((notification) => notification.status === 'Read').length,
//     },
//   ];

//   // Fetch initial notifications on component mount
//   useEffect(() => {
//     const fetchNotifications = async () => {
//       try {
//         const response = await axios.get(
//           `http://localhost:8080/rest/notifications/user/${user.userId}`,
//           {
//             headers: {
//               Authorization: `Bearer ${token}`,
//             },
//           }
//         );

//         if (response.status === 200) {
//           // The data is assumed to be an array of notifications from the API
//           const data = response.data; // Notifications returned by the server

//           // Transform the API notifications to match the WebSocket format
//           const formattedNotifications = data.map((notification) => ({
//             id: notification.id, // Assigning a unique id for each notification
//             message: notification.message, // Assuming the API returns 'message'
//             status: notification.read ? 'Read' : 'Unread', // Default status
//             timestamp: notification.createdAt, // Add the current timestamp
//           }));

//           console.log('Notifications data:', formattedNotifications);

//           // Update state with the formatted notifications
//           setNotifications(formattedNotifications);
//         } else {
//           enqueueSnackbar('Failed to load notifications', { variant: 'error' });
//         }
//       } catch (error) {
//         enqueueSnackbar('Error fetching notifications', { variant: 'error' });
//         console.error('Error fetching notifications:', error);
//       }
//     };

//     fetchNotifications();
//   }, [enqueueSnackbar, user.userId, token]);

//   // Filter notifications based on currentTab
//   useEffect(() => {
//     const filterNotifications = () => {
//       switch (currentTab) {
//         case 'unread':
//           return notifications.filter((notification) => notification.status === 'Unread');
//         case 'read':
//           return notifications.filter((notification) => notification.status === 'Read');
//         default:
//           return notifications;
//       }
//     };
//     setFilteredNotifications(filterNotifications());
//   }, [currentTab, notifications]);

//   // Handle WebSocket connection and new notifications (same as before)
//   useEffect(() => {
//     const stompClient = new Client({
//       webSocketFactory: () => {
//         const socketUrl = `http://localhost:8080/rest/ws?token=${token}`;
//         return new SockJS(socketUrl);
//       },
//       onConnect: () => {
//         console.log('WebSocket connected');
//         stompClient.subscribe(`/topic/user/${user.userId}`, (data) => {
//           const message = JSON.parse(data.body);
//           console.log(message);
//           const newNotification = {
//             id: new Date().getTime(),
//             message: message.message,
//             status: 'Unread',
//             timestamp: new Date(),
//           };
//           if (newNotification.message && newNotification.status === 'Unread') {
//             enqueueSnackbar('New Notification!', { variant: 'info', autoHideDuration: 10000 });
//             setNotifications((prevNotifications) => {
//               const updatedNotifications = [newNotification, ...prevNotifications];
//               return updatedNotifications;
//             });
//           }
//         });
//       },
//       onDisconnect: () => {
//         console.log('Disconnected from WebSocket');
//       },
//       onStompError: (error) => {
//         console.error('STOMP Error:', error);
//       },
//     });

//     stompClient.activate();
//     return () => stompClient.deactivate();
//   }, [enqueueSnackbar, user.userId, token]);

//   // Limit to the latest 10 notifications or all based on showAllNotifications state
//   const displayedNotifications = showAllNotifications
//     ? filteredNotifications
//     : filteredNotifications.slice(0, 10);

//   const handleNotificationClick = async (notification) => {
//     try {
//       const response = await axios.put(
//         `http://localhost:8080/rest/notifications/notification/${notification.id}`,
//         { isRead: true }, // Mark the notification as read
//         {
//           headers: {
//             Authorization: `Bearer ${token}`,
//           },
//         }
//       );

//       if (response.status === 200) {
//         // Update the local state to reflect the read status
//         const updatedNotifications = notifications.map((item) =>
//           item.id === notification.id ? { ...item, status: 'Read' } : item
//         );
//         setNotifications(updatedNotifications);

//         // Optionally, navigate to the notification's related page
//         if (notification.screenId) {
//           if (notification.screenId.screenNm === 'Item Requisition') {
//             router.push(paths.dashboard.inventory.ItemRequisition);
//           }
//         }
//       } else {
//         enqueueSnackbar('Failed to mark as read', { variant: 'error' });
//       }
//     } catch (error) {
//       enqueueSnackbar('Error marking notification as read', { variant: 'error' });
//       console.error('Error marking notification as read:', error);
//     }
//   };

//   const handleChangeTab = (event, newValue) => {
//     setCurrentTab(newValue);
//   };

//   const handleMarkAllAsRead = async () => {
//     try {
//       const response = await axios.put(
//         `http://localhost:8080/rest/notifications/user/${user.userId}/all`,
//         { isRead: true }, // Mark all notifications as read
//         {
//           headers: {
//             Authorization: `Bearer ${token}`,
//           },
//         }
//       );

//       if (response.status === 200) {
//         // Update the local state to mark all notifications as read
//         const updatedNotifications = notifications.map((notification) => ({
//           ...notification,
//           status: 'Read',
//         }));
//         setNotifications(updatedNotifications);
//       } else {
//         enqueueSnackbar('Failed to mark all as read', { variant: 'error' });
//       }
//     } catch (error) {
//       enqueueSnackbar('Error marking all notifications as read', { variant: 'error' });
//       console.error('Error marking all notifications as read:', error);
//     }
//   };

//   const handleDeleteNotification = (event, notificationId) => {
//     event.stopPropagation();
//     const updatedNotifications = notifications.filter(
//       (notification) => notification.id !== notificationId
//     );
//     setNotifications(updatedNotifications);
//   };

//   const handleDeleteAllNotifications = () => {
//     setNotifications([]);
//   };

//   const renderHead = (
//     <Stack
//       direction="row"
//       alignItems="center"
//       sx={{
//         py: 1.5,
//         pl: 2.5,
//         pr: 1,
//         backgroundColor: '#fffff',
//         transition: 'all 0.3s ease',
//       }}
//     >
//       <Typography variant="h6" sx={{ flexGrow: 1 }}>
//         Notifications
//       </Typography>
//       <Tooltip title="Mark all as read">
//         <IconButton onClick={handleMarkAllAsRead} color="primary">
//           <Iconify icon="eva:done-all-fill" />
//         </IconButton>
//       </Tooltip>
//       <IconButton onClick={handleDeleteAllNotifications} sx={{ color: '#f1512e' }}>
//         <Tooltip title="Delete all notifications">
//           <Iconify icon="mdi:delete-forever" width={20} height={20} />
//         </Tooltip>
//       </IconButton>

//       <IconButton onClick={drawer.onFalse} sx={{ color: '#f1512e' }}>
//         <Tooltip title="Close notifications">
//           <Iconify icon="mingcute:close-line" />
//         </Tooltip>
//       </IconButton>
//     </Stack>
//   );

//   const renderTabs = (
//     <Tabs
//       value={currentTab}
//       onChange={handleChangeTab}
//       sx={{ display: 'flex', justifyContent: 'space-between' }}
//     >
//       {TABS.map((tab) => (
//         <Tab
//           key={tab.value}
//           value={tab.value}
//           label={tab.label}
//           icon={
//             <Label
//               variant={tab.value === currentTab ? 'filled' : 'soft'}
//               color={tab.value === 'unread' ? 'info' : 'success'}
//               sx={{ fontSize: '0.85rem', padding: '2px 8px' }}
//             >
//               {tab.count}
//             </Label>
//           }
//           sx={{
//             '&.Mui-selected': {
//               backgroundColor: '#eeeeee',
//               borderRadius: '8px',
//               color: '#1976d2',
//               transition: 'all 0.2s ease',
//             },
//             '&.MuiTab-root': {
//               padding: '0 16px',
//               fontSize: '0.9rem',
//               fontWeight: 'bold',
//             },
//           }}
//         />
//       ))}
//     </Tabs>
//   );

//   const renderList = (
//     <Scrollbar>
//       {Array.isArray(displayedNotifications) && displayedNotifications.length > 0 ? (
//         <List disablePadding>
//           {displayedNotifications.map((notification) => (
//             <ListItemButton
//               key={notification.id}
//               disableRipple
//               sx={{
//                 p: 2.5,
//                 alignItems: 'flex-start',
//                 borderBottom: (theme) => `dashed 1px ${theme.palette.divider}`,
//                 position: 'relative',
//               }}
//               onClick={() => handleNotificationClick(notification)}
//             >
//               <ListItemAvatar>
//                 <Stack
//                   alignItems="center"
//                   justifyContent="center"
//                   sx={{
//                     width: 30,
//                     height: 30,
//                     borderRadius: '50%',
//                     backgroundColor: notification.status === 'Unread' ? '#e3f2fd' : 'transparent',
//                   }}
//                 >
//                   <Iconify
//                     icon={notification.status === 'Unread' ? 'mdi:bell' : 'mdi:bell-off'}
//                     width={20}
//                     height={20}
//                     color={notification.status === 'Unread' ? '#1976d2' : '#999'}
//                   />
//                 </Stack>
//               </ListItemAvatar>

//               <ListItemText
//                 disableTypography
//                 primary={notification.message}
//                 secondary={
//                   <Stack
//                     direction="row"
//                     alignItems="center"
//                     justifyContent="space-between"
//                     sx={{ typography: 'caption', color: 'text.disabled' }}
//                   >
//                     <Typography variant="caption" sx={{ marginRight: 1 }}>
//                       {formatDistanceToNow(new Date(notification.timestamp), { addSuffix: true })}
//                     </Typography>

//                     <Tooltip title="Delete Notification">
//                       <Iconify
//                         icon="mdi:delete"
//                         width={20}
//                         height={20}
//                         color="#f44336"
//                         onClick={(e) => handleDeleteNotification(e, notification.id)}
//                         sx={{
//                           cursor: 'pointer',
//                           '&:hover': {
//                             color: '#d32f2f',
//                           },
//                         }}
//                       />
//                     </Tooltip>
//                   </Stack>
//                 }
//               />
//             </ListItemButton>
//           ))}
//         </List>
//       ) : (
//         <Typography variant="body2" sx={{ p: 2, textAlign: 'center', color: '#888' }}>
//           No notifications available
//         </Typography>
//       )}

//       {/* See All button */}
//       {!showAllNotifications && filteredNotifications.length > 10 && (
//         <Button
//           sx={{
//             width: '100%',
//             marginTop: 2,
//             backgroundColor: '#1976d2',
//             color: 'white',
//             borderRadius: 1.5,
//             '&:hover': {
//               backgroundColor: '#1565c0',
//             },
//           }}
//           onClick={() => setShowAllNotifications(true)}
//         >
//           See All
//         </Button>
//       )}
//     </Scrollbar>
//   );

//   // const renderList = (
//   //   <Scrollbar>
//   //     {Array.isArray(displayedNotifications) && displayedNotifications.length > 0 ? (
//   //       <List disablePadding>
//   //         {displayedNotifications.map((notification) => (
//   //           <ListItemButton
//   //             key={notification.id}
//   //             disableRipple
//   //             sx={{
//   //               p: 2.5,
//   //               alignItems: 'flex-start',
//   //               borderBottom: (theme) => `dashed 1px ${theme.palette.divider}`,
//   //               position: 'relative',
//   //             }}
//   //             onClick={() => handleNotificationClick(notification)}
//   //           >
//   //             <ListItemAvatar>
//   //               <Stack
//   //                 alignItems="center"
//   //                 justifyContent="center"
//   //                 sx={{
//   //                   width: 30,
//   //                   height: 30,
//   //                   borderRadius: '50%',
//   //                   backgroundColor: notification.status === 'Unread' ? '#e3f2fd' : 'transparent',
//   //                 }}
//   //               >
//   //                 <Iconify
//   //                   icon={notification.status === 'Unread' ? 'mdi:bell' : 'mdi:bell-off'}
//   //                   width={20}
//   //                   height={20}
//   //                   color={notification.status === 'Unread' ? '#1976d2' : '#999'}
//   //                 />
//   //               </Stack>
//   //             </ListItemAvatar>

//   //             <ListItemText
//   //               disableTypography
//   //               primary={notification.message}
//   //               secondary={
//   //                 <Stack
//   //                   direction="row"
//   //                   alignItems="center"
//   //                   justifyContent="space-between"
//   //                   sx={{ typography: 'caption', color: 'text.disabled' }}
//   //                 >
//   //                   <Typography variant="caption" sx={{ marginRight: 1 }}>
//   //                     {formatDistanceToNow(new Date(notification.timestamp), { addSuffix: true })}
//   //                   </Typography>

//   //                   <Tooltip title="Delete Notification">
//   //                     <Iconify
//   //                       icon="mdi:delete"
//   //                       width={20}
//   //                       height={20}
//   //                       color="#f44336"
//   //                       onClick={(e) => handleDeleteNotification(e, notification.id)}
//   //                       sx={{
//   //                         cursor: 'pointer',
//   //                         '&:hover': {
//   //                           color: '#d32f2f',
//   //                         },
//   //                       }}
//   //                     />
//   //                   </Tooltip>
//   //                 </Stack>
//   //               }
//   //             />
//   //           </ListItemButton>
//   //         ))}
//   //       </List>
//   //     ) : (
//   //       <Typography variant="body2" sx={{ p: 2, textAlign: 'center', color: '#888' }}>
//   //         No notifications available
//   //       </Typography>
//   //     )}

//   //     {/* See All button */}
//   //     {!showAllNotifications && filteredNotifications.length > 10 && (
//   //       <Button
//   //         sx={{
//   //           width: '100%',
//   //           marginTop: 2,
//   //           backgroundColor: '#1976d2',
//   //           color: 'white',
//   //           borderRadius: 1.5,
//   //           '&:hover': {
//   //             backgroundColor: '#1565c0',
//   //           },
//   //         }}
//   //         onClick={() => setShowAllNotifications(true)}
//   //       >
//   //         See All
//   //       </Button>
//   //     )}
//   //   </Scrollbar>
//   // );

//   return (
//     <>
//       <IconButton color="default" onClick={drawer.onTrue}>
//         <Badge
//           badgeContent={totalUnRead}
//           color="error"
//           sx={{
//             transition: 'all 0.3s ease',
//             '& .MuiBadge-dot': {
//               backgroundColor: '#d32f2f',
//               animation: 'pulse 1s infinite',
//             },
//           }}
//         >
//           <Iconify icon="mdi:notifications-active" width={24} height={26} color="#ffbe4d" />
//         </Badge>
//       </IconButton>

//       <Drawer
//         open={drawer.value}
//         onClose={() => {
//           drawer.onFalse();
//           setShowAllNotifications(false);
//         }}
//         anchor="right"
//         slotProps={{
//           backdrop: { invisible: true },
//         }}
//         PaperProps={{
//           sx: {
//             width: 1,
//             maxWidth: 420,
//             padding: 2,
//             boxShadow: 3,
//             borderRadius: '8px 0 0 8px',
//             transition: 'all 0.3s ease',
//           },
//         }}
//       >
//         {renderHead}
//         {renderTabs}
//         <Divider />
//         {renderList}
//       </Drawer>
//     </>

//   );
// }

// import { useState, useEffect } from 'react';
// import {
//   Stack,
//   Badge,
//   IconButton,
//   Drawer,
//   Divider,
//   Tabs,
//   Tab,
//   Typography,
//   List,
//   ListItem,
//   ListItemText,
//   ListItemIcon,
//   Tooltip,
//   Button,
//   ListItemButton,
//   ListItemAvatar,
// } from '@mui/material';
// import { formatDistanceToNow } from 'date-fns';
// import Iconify from 'src/components/iconify';
// import { useSnackbar } from 'src/components/snackbar';
// import { useRouter } from 'src/routes/hooks';
// import { paths } from 'src/routes/paths';
// import Label from 'src/components/label';
// import Scrollbar from 'src/components/scrollbar';
// import { useBoolean } from 'src/hooks/use-boolean';
// import SockJS from 'sockjs-client';
// // eslint-disable-next-line import/no-extraneous-dependencies
// import { Client } from '@stomp/stompjs';
// import axios from 'axios';

// export default function NotificationsPopover() {
//   const router = useRouter();
//   const { enqueueSnackbar } = useSnackbar();
//   const [notifications, setNotifications] = useState([]);
//   const [filteredNotifications, setFilteredNotifications] = useState([]);
//   const [currentTab, setCurrentTab] = useState('unread');
//   const [showAllNotifications, setShowAllNotifications] = useState(false); // New state for "See All"
//   const token = sessionStorage.getItem('token');
//   const user = JSON.parse(sessionStorage.getItem('user'));
//   const drawer = useBoolean();
//   const totalUnRead = notifications.filter(
//     (notification) => notification.status === 'Unread'
//   ).length;

//   const TABS = [
//     { value: 'unread', label: 'Unread', count: totalUnRead },
//     {
//       value: 'read',
//       label: 'Read',
//       count: notifications.filter((notification) => notification.status === 'Read').length,
//     },
//   ];

//   // Fetch initial notifications on component mount
//   useEffect(() => {
//     const fetchNotifications = async () => {
//       try {
//         const response = await axios.get(
//           `http://localhost:8080/rest/notifications/user/${user.userId}`,
//           {
//             headers: {
//               Authorization: `Bearer ${token}`,
//             },
//           }
//         );

//         if (response.status === 200) {
//           // The data is assumed to be an array of notifications from the API
//           const data = response.data; // Notifications returned by the server

//           // Transform the API notifications to match the WebSocket format
//           const formattedNotifications = data.map((notification) => ({
//             id: notification.id, // Assigning a unique id for each notification
//             message: notification.message, // Assuming the API returns 'message'
//             status: notification.read ? 'Read' : 'Unread', // Default status
//             timestamp: notification.createdAt, // Add the current timestamp
//           }));

//           console.log('Notifications data:', formattedNotifications);

//           // Update state with the formatted notifications
//           setNotifications(formattedNotifications);
//         } else {
//           enqueueSnackbar('Failed to load notifications', { variant: 'error' });
//         }
//       } catch (error) {
//         enqueueSnackbar('Error fetching notifications', { variant: 'error' });
//         console.error('Error fetching notifications:', error);
//       }
//     };

//     fetchNotifications();
//   }, [enqueueSnackbar, user.userId, token]);

//   // Filter notifications based on currentTab
//   useEffect(() => {
//     const filterNotifications = () => {
//       switch (currentTab) {
//         case 'unread':
//           return notifications.filter((notification) => notification.status === 'Unread');
//         case 'read':
//           return notifications.filter((notification) => notification.status === 'Read');
//         default:
//           return notifications;
//       }
//     };
//     setFilteredNotifications(filterNotifications());
//   }, [currentTab, notifications]);

//   // Handle WebSocket connection and new notifications (same as before)
//   useEffect(() => {
//     const stompClient = new Client({
//       webSocketFactory: () => {
//         const socketUrl = `http://localhost:8080/rest/ws?token=${token}`;
//         return new SockJS(socketUrl);
//       },
//       onConnect: () => {
//         console.log('WebSocket connected');
//         stompClient.subscribe(`/topic/user/${user.userId}`, (data) => {
//           const message = JSON.parse(data.body);
//           console.log(message);
//           const newNotification = {
//             id: new Date().getTime(),
//             message: message.message,
//             status: 'Unread',
//             timestamp: new Date(),
//           };
//           if (newNotification.message && newNotification.status === 'Unread') {
//             enqueueSnackbar('New Notification!', { variant: 'info', autoHideDuration: 10000 });
//             setNotifications((prevNotifications) => {
//               const updatedNotifications = [newNotification, ...prevNotifications];
//               return updatedNotifications;
//             });
//           }
//         });
//       },
//       onDisconnect: () => {
//         console.log('Disconnected from WebSocket');
//       },
//       onStompError: (error) => {
//         console.error('STOMP Error:', error);
//       },
//     });

//     stompClient.activate();
//     return () => stompClient.deactivate();
//   }, [enqueueSnackbar, user.userId, token]);

//   // Limit to the latest 10 notifications or all based on showAllNotifications state
//   const displayedNotifications = showAllNotifications
//     ? filteredNotifications
//     : filteredNotifications.slice(0, 10);

//   const handleNotificationClick = (notification) => {
//     const updatedNotifications = notifications.map((item) =>
//       item.id === notification.id ? { ...item, status: 'Read' } : item
//     );
//     setNotifications(updatedNotifications);

//     if (notification.screenId) {
//       if (notification.screenId.screenNm === 'Item Requisition') {
//         router.push(paths.dashboard.inventory.ItemRequisition);
//       }
//     }
//   };

//   const handleChangeTab = (event, newValue) => {
//     setCurrentTab(newValue);
//   };

//   const handleMarkAllAsRead = () => {
//     const updatedNotifications = notifications.map((notification) => ({
//       ...notification,
//       status: 'Read',
//     }));
//     setNotifications(updatedNotifications);
//   };

//   const handleDeleteNotification = (event, notificationId) => {
//     event.stopPropagation();
//     const updatedNotifications = notifications.filter(
//       (notification) => notification.id !== notificationId
//     );
//     setNotifications(updatedNotifications);
//   };

//   const handleDeleteAllNotifications = () => {
//     setNotifications([]);
//   };

//   const renderHead = (
//     <Stack
//       direction="row"
//       alignItems="center"
//       sx={{
//         py: 1.5,
//         pl: 2.5,
//         pr: 1,
//         backgroundColor: '#fffff',
//         transition: 'all 0.3s ease',
//       }}
//     >
//       <Typography variant="h6" sx={{ flexGrow: 1 }}>
//         Notifications
//       </Typography>
//       <Tooltip title="Mark all as read">
//         <IconButton onClick={handleMarkAllAsRead} color="primary">
//           <Iconify icon="eva:done-all-fill" />
//         </IconButton>
//       </Tooltip>
//       <IconButton onClick={handleDeleteAllNotifications} sx={{ color: '#f1512e' }}>
//         <Tooltip title="Delete all notifications">
//           <Iconify icon="mdi:delete-forever" width={20} height={20} />
//         </Tooltip>
//       </IconButton>

//       <IconButton onClick={drawer.onFalse} sx={{ color: '#f1512e' }}>
//         <Tooltip title="Close notifications">
//           <Iconify icon="mingcute:close-line" />
//         </Tooltip>
//       </IconButton>
//     </Stack>
//   );

//   const renderTabs = (
//     <Tabs
//       value={currentTab}
//       onChange={handleChangeTab}
//       sx={{ display: 'flex', justifyContent: 'space-between' }}
//     >
//       {TABS.map((tab) => (
//         <Tab
//           key={tab.value}
//           value={tab.value}
//           label={tab.label}
//           icon={
//             <Label
//               variant={tab.value === currentTab ? 'filled' : 'soft'}
//               color={tab.value === 'unread' ? 'info' : 'success'}
//               sx={{ fontSize: '0.85rem', padding: '2px 8px' }}
//             >
//               {tab.count}
//             </Label>
//           }
//           sx={{
//             '&.Mui-selected': {
//               backgroundColor: '#eeeeee',
//               borderRadius: '8px',
//               color: '#1976d2',
//               transition: 'all 0.2s ease',
//             },
//             '&.MuiTab-root': {
//               padding: '0 16px',
//               fontSize: '0.9rem',
//               fontWeight: 'bold',
//             },
//           }}
//         />
//       ))}
//     </Tabs>
//   );

//   const renderList = (
//     <Scrollbar>
//       {Array.isArray(displayedNotifications) && displayedNotifications.length > 0 ? (
//         <List disablePadding>
//           {displayedNotifications.map((notification) => (
//             <ListItemButton
//               key={notification.id}
//               disableRipple
//               sx={{
//                 p: 2.5,
//                 alignItems: 'flex-start',
//                 borderBottom: (theme) => `dashed 1px ${theme.palette.divider}`,
//                 position: 'relative',
//               }}
//               onClick={() => handleNotificationClick(notification)}
//             >
//               <ListItemAvatar>
//                 <Stack
//                   alignItems="center"
//                   justifyContent="center"
//                   sx={{
//                     width: 30,
//                     height: 30,
//                     borderRadius: '50%',
//                     backgroundColor: notification.status === 'Unread' ? '#e3f2fd' : 'transparent',
//                   }}
//                 >
//                   <Iconify
//                     icon={notification.status === 'Unread' ? 'mdi:bell' : 'mdi:bell-off'}
//                     width={20}
//                     height={20}
//                     color={notification.status === 'Unread' ? '#1976d2' : '#999'}
//                   />
//                 </Stack>
//               </ListItemAvatar>

//               <ListItemText
//                 disableTypography
//                 primary={notification.message}
//                 secondary={
//                   <Stack
//                     direction="row"
//                     alignItems="center"
//                     justifyContent="space-between"
//                     sx={{ typography: 'caption', color: 'text.disabled' }}
//                   >
//                     <Typography variant="caption" sx={{ marginRight: 1 }}>
//                       {formatDistanceToNow(new Date(notification.timestamp), { addSuffix: true })}
//                     </Typography>

//                     <Tooltip title="Delete Notification">
//                       <Iconify
//                         icon="mdi:delete"
//                         width={20}
//                         height={20}
//                         color="#f44336"
//                         onClick={(e) => handleDeleteNotification(e, notification.id)}
//                         sx={{
//                           cursor: 'pointer',
//                           '&:hover': {
//                             color: '#d32f2f',
//                           },
//                         }}
//                       />
//                     </Tooltip>
//                   </Stack>
//                 }
//               />
//             </ListItemButton>
//           ))}
//         </List>
//       ) : (
//         <Typography variant="body2" sx={{ p: 2, textAlign: 'center', color: '#888' }}>
//           No notifications available
//         </Typography>
//       )}

//       {/* See All button */}
//       {!showAllNotifications && filteredNotifications.length > 10 && (
//         <Button
//           sx={{
//             width: '100%',
//             marginTop: 2,
//             backgroundColor: '#1976d2',
//             color: 'white',
//             borderRadius: 1.5,
//             '&:hover': {
//               backgroundColor: '#1565c0',
//             },
//           }}
//           onClick={() => setShowAllNotifications(true)}
//         >
//           See All
//         </Button>
//       )}
//     </Scrollbar>
//   );

//   return (
//     <>
//       <IconButton color="default" onClick={drawer.onTrue}>
//         <Badge
//           badgeContent={totalUnRead}
//           color="error"
//           sx={{
//             transition: 'all 0.3s ease',
//             '& .MuiBadge-dot': {
//               backgroundColor: '#d32f2f',
//               animation: 'pulse 1s infinite',
//             },
//           }}
//         >
//           <Iconify icon="mdi:notifications-active" width={24} height={26} color="#ffbe4d" />
//         </Badge>
//       </IconButton>

//       <Drawer
//         open={drawer.value}
//         onClose={() => {
//           drawer.onFalse();
//           setShowAllNotifications(false);
//         }}
//         anchor="right"
//         slotProps={{
//           backdrop: { invisible: true },
//         }}
//         PaperProps={{
//           sx: {
//             width: 1,
//             maxWidth: 420,
//             padding: 2,
//             boxShadow: 3,
//             borderRadius: '8px 0 0 8px',
//             transition: 'all 0.3s ease',
//           },
//         }}
//       >
//         {renderHead}
//         {renderTabs}
//         <Divider />
//         {renderList}
//       </Drawer>
//     </>
//   );
// }

// import { useState, useEffect } from 'react';
// import {
//   Stack,
//   Badge,
//   IconButton,
//   Drawer,
//   Divider,
//   Tabs,
//   Tab,
//   Typography,
//   List,
//   ListItem,
//   ListItemText,
//   ListItemIcon,
//   Tooltip,
//   Button,
//   ListItemButton,
//   ListItemAvatar,
// } from '@mui/material';
// import { formatDistanceToNow } from 'date-fns';
// import Iconify from 'src/components/iconify';
// import { useSnackbar } from 'src/components/snackbar';
// import { useRouter } from 'src/routes/hooks';
// import { paths } from 'src/routes/paths';
// import Label from 'src/components/label';
// import Scrollbar from 'src/components/scrollbar';
// import { useBoolean } from 'src/hooks/use-boolean';
// import SockJS from 'sockjs-client';
// // eslint-disable-next-line import/no-extraneous-dependencies
// import { Client } from '@stomp/stompjs';

// export default function NotificationsPopover() {
//   const router = useRouter();
//   const { enqueueSnackbar } = useSnackbar();
//   const [notifications, setNotifications] = useState(() => {
//     const storedNotifications = localStorage.getItem('notifications');
//     return storedNotifications ? JSON.parse(storedNotifications) : [];
//   });
//   const [filteredNotifications, setFilteredNotifications] = useState([]);
//   const [currentTab, setCurrentTab] = useState('unread');
//   const [showAllNotifications, setShowAllNotifications] = useState(false); // New state for "See All"
//   const token = sessionStorage.getItem('token');
//   const user = JSON.parse(sessionStorage.getItem('user'));
//   const drawer = useBoolean();
//   const totalUnRead = notifications.filter(
//     (notification) => notification.status === 'Unread'
//   ).length;

//   const TABS = [
//     { value: 'unread', label: 'Unread', count: totalUnRead },
//     {
//       value: 'read',
//       label: 'Read',
//       count: notifications.filter((notification) => notification.status === 'Read').length,
//     },
//   ];

//   useEffect(() => {
//     const filterNotifications = () => {
//       switch (currentTab) {
//         case 'unread':
//           return notifications.filter((notification) => notification.status === 'Unread');
//         case 'read':
//           return notifications.filter((notification) => notification.status === 'Read');
//         default:
//           return notifications;
//       }
//     };
//     setFilteredNotifications(filterNotifications());
//   }, [currentTab, notifications]);

//   // Handle WebSocket connection and new notifications (same as before)
//   useEffect(() => {
//     const stompClient = new Client({
//       webSocketFactory: () => {
//         const socketUrl = `http://localhost:8080/rest/ws?token=${token}`;
//         return new SockJS(socketUrl);
//       },
//       onConnect: () => {
//         console.log('WebSocket connected');
//         stompClient.subscribe(`/topic/user/${user.userId}`, (data) => {
//           const message = JSON.parse(data.body);
//           console.log(message);
//           const newNotification = {
//             id: new Date().getTime(),
//             message: message.message,
//             status: 'Unread',
//             timestamp: new Date(),
//           };
//           if (newNotification.message && newNotification.status === 'Unread') {
//             enqueueSnackbar('New Notification!', { variant: 'info', autoHideDuration: 10000 });
//             setNotifications((prevNotifications) => {
//               const updatedNotifications = [newNotification, ...prevNotifications];
//               localStorage.setItem('notifications', JSON.stringify(updatedNotifications));
//               return updatedNotifications;
//             });
//           }
//         });
//       },
//       onDisconnect: () => {
//         console.log('Disconnected from WebSocket');
//       },
//       onStompError: (error) => {
//         console.error('STOMP Error:', error);
//       },
//     });

//     stompClient.activate();
//     return () => stompClient.deactivate();
//   }, [enqueueSnackbar, user.userId, token]);

//   // Limit to the latest 10 notifications or all based on showAllNotifications state
//   const displayedNotifications = showAllNotifications
//     ? filteredNotifications
//     : filteredNotifications.slice(0, 10);

//   const handleNotificationClick = (notification) => {
//     const updatedNotifications = notifications.map((item) =>
//       item.id === notification.id ? { ...item, status: 'Read' } : item
//     );
//     setNotifications(updatedNotifications);
//     localStorage.setItem('notifications', JSON.stringify(updatedNotifications));

//     if (notification.screenId) {
//       if (notification.screenId.screenNm === 'Item Requisition') {
//         router.push(paths.dashboard.inventory.ItemRequisition);
//       }
//     }
//   };

//   const handleChangeTab = (event, newValue) => {
//     setCurrentTab(newValue);
//   };

//   const handleMarkAllAsRead = () => {
//     const updatedNotifications = notifications.map((notification) => ({
//       ...notification,
//       status: 'Read',
//     }));
//     setNotifications(updatedNotifications);
//     localStorage.setItem('notifications', JSON.stringify(updatedNotifications));
//   };

//   const handleDeleteNotification = (event, notificationId) => {
//     event.stopPropagation();
//     const updatedNotifications = notifications.filter(
//       (notification) => notification.id !== notificationId
//     );
//     setNotifications(updatedNotifications);
//     localStorage.setItem('notifications', JSON.stringify(updatedNotifications));
//   };

//   const handleDeleteAllNotifications = () => {
//     setNotifications([]);
//     localStorage.setItem('notifications', JSON.stringify([]));
//   };

//   const renderHead = (
//     <Stack
//       direction="row"
//       alignItems="center"
//       sx={{
//         py: 1.5,
//         pl: 2.5,
//         pr: 1,
//         backgroundColor: '#fffff',
//         transition: 'all 0.3s ease',
//       }}
//     >
//       <Typography variant="h6" sx={{ flexGrow: 1 }}>
//         Notifications
//       </Typography>
//       <Tooltip title="Mark all as read">
//         <IconButton onClick={handleMarkAllAsRead} color="primary">
//           <Iconify icon="eva:done-all-fill" />
//         </IconButton>
//       </Tooltip>
//       <IconButton onClick={handleDeleteAllNotifications} sx={{ color: '#f1512e' }}>
//         <Tooltip title="Delete all notifications">
//           <Iconify icon="mdi:delete-forever" width={20} height={20} />
//         </Tooltip>
//       </IconButton>

//       <IconButton onClick={drawer.onFalse} sx={{ color: '#f1512e' }}>
//         <Tooltip title="Close notifications">
//           <Iconify icon="mingcute:close-line" />
//         </Tooltip>
//       </IconButton>
//     </Stack>
//   );

//   const renderTabs = (
//     <Tabs
//       value={currentTab}
//       onChange={handleChangeTab}
//       sx={{ display: 'flex', justifyContent: 'space-between' }}
//     >
//       {TABS.map((tab) => (
//         <Tab
//           key={tab.value}
//           value={tab.value}
//           label={tab.label}
//           icon={
//             <Label
//               variant={tab.value === currentTab ? 'filled' : 'soft'}
//               color={tab.value === 'unread' ? 'info' : 'success'}
//               sx={{ fontSize: '0.85rem', padding: '2px 8px' }}
//             >
//               {tab.count}
//             </Label>
//           }
//           sx={{
//             '&.Mui-selected': {
//               backgroundColor: '#eeeeee',
//               borderRadius: '8px',
//               color: '#1976d2',
//               transition: 'all 0.2s ease',
//             },
//             '&.MuiTab-root': {
//               padding: '0 16px',
//               fontSize: '0.9rem',
//               fontWeight: 'bold',
//             },
//           }}
//         />
//       ))}
//     </Tabs>
//   );

//   const renderList = (
//     <Scrollbar>
//       {Array.isArray(displayedNotifications) && displayedNotifications.length > 0 ? (
//         <List disablePadding>
//           {displayedNotifications.map((notification) => (
//             <ListItemButton
//               key={notification.id}
//               disableRipple
//               sx={{
//                 p: 2.5,
//                 alignItems: 'flex-start',
//                 borderBottom: (theme) => `dashed 1px ${theme.palette.divider}`,
//                 position: 'relative',
//               }}
//               onClick={() => handleNotificationClick(notification)}
//             >
//               <ListItemAvatar>
//                 <Stack
//                   alignItems="center"
//                   justifyContent="center"
//                   sx={{
//                     width: 30,
//                     height: 30,
//                     borderRadius: '50%',
//                     backgroundColor: notification.status === 'Unread' ? '#e3f2fd' : 'transparent',
//                   }}
//                 >
//                   <Iconify
//                     icon={notification.status === 'Unread' ? 'mdi:bell' : 'mdi:bell-off'}
//                     width={20}
//                     height={20}
//                     color={notification.status === 'Unread' ? '#1976d2' : '#999'}
//                   />
//                 </Stack>
//               </ListItemAvatar>

//               <ListItemText
//                 disableTypography
//                 primary={notification.message}
//                 secondary={
//                   <Stack
//                     direction="row"
//                     alignItems="center"
//                     justifyContent="space-between"
//                     sx={{ typography: 'caption', color: 'text.disabled' }}
//                   >
//                     {/* Format timestamp aligned to the left */}
//                     <Typography variant="caption" sx={{ marginRight: 1 }}>
//                       {formatDistanceToNow(new Date(notification.timestamp), { addSuffix: true })}
//                     </Typography>

//                     {/* Delete Icon aligned to the right */}
//                     <Tooltip title="Delete Notification">
//                       <Iconify
//                         icon="mdi:delete"
//                         width={20}
//                         height={20}
//                         color="#f44336"
//                         onClick={(e) => handleDeleteNotification(e, notification.id)}
//                         sx={{
//                           cursor: 'pointer',
//                           '&:hover': {
//                             color: '#d32f2f',
//                           },
//                         }}
//                       />
//                     </Tooltip>
//                   </Stack>
//                 }
//               />
//             </ListItemButton>
//           ))}
//         </List>
//       ) : (
//         <Typography variant="body2" sx={{ p: 2, textAlign: 'center', color: '#888' }}>
//           No notifications available
//         </Typography>
//       )}

//       {/* See All button */}
//       {!showAllNotifications && filteredNotifications.length > 10 && (
//         <Button
//           sx={{
//             width: '100%',
//             marginTop: 2,
//             backgroundColor: '#1976d2',
//             color: 'white',
//             borderRadius: 1.5,
//             '&:hover': {
//               backgroundColor: '#1565c0',
//             },
//           }}
//           onClick={() => setShowAllNotifications(true)}
//         >
//           See All
//         </Button>
//       )}
//     </Scrollbar>
//   );

//   return (
//     <>
//       <IconButton color="default" onClick={drawer.onTrue}>
//         <Badge
//           badgeContent={totalUnRead}
//           color="error"
//           sx={{
//             transition: 'all 0.3s ease',
//             '& .MuiBadge-dot': {
//               backgroundColor: '#d32f2f',
//               animation: 'pulse 1s infinite',
//             },
//           }}
//         >
//           <Iconify icon="mdi:notifications-active" width={24} height={26} color="#ffbe4d" />
//         </Badge>
//       </IconButton>

//       <Drawer
//         open={drawer.value}
//         onClose={() => {
//           drawer.onFalse();
//           setShowAllNotifications(false);
//         }}
//         anchor="right"
//         slotProps={{
//           backdrop: { invisible: true },
//         }}
//         PaperProps={{
//           sx: {
//             width: 1,
//             maxWidth: 420,
//             padding: 2,
//             boxShadow: 3,
//             borderRadius: '8px 0 0 8px',
//             transition: 'all 0.3s ease',
//           },
//         }}
//       >
//         {renderHead}
//         {renderTabs}
//         <Divider />
//         {renderList}
//       </Drawer>
//     </>
//   );
// }
