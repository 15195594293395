// ----------------------------------------------------------------------

// export const HEADER = {
//   H_MOBILE: 64,
//   H_DESKTOP: 80,
//   H_DESKTOP_OFFSET: 80 - 16,
// };

// export const NAV = {
//   W_VERTICAL: 280,
//   W_MINI: 88,
// };

export const HEADER = {
  H_MOBILE: 40,  // 64
  H_DESKTOP: 42, // 80
  H_DESKTOP_OFFSET: 56, // 80-16
};

export const NAV = {
  W_VERTICAL: 230, // 280
  W_MINI: 88,
};
